/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AggregateBalances {
  /** @format bigint */
  asset_id?: number
  /** @format numeric */
  balance?: number
  /** @format bigint */
  exchange_id?: number
  /** @format numeric */
  free?: number
  /** @format bigint */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
}

export interface AggregateColdBalances {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format numeric */
  balance?: number
  /**
   * Note: This is a Foreign Key to `exchanges.id`.<fk table='exchanges'
   * column='id'/>
   *
   * @format bigint
   */
  exchange_id?: number
  /** @format bigint */
  tenant_id?: number
}

export interface AggregateDeltas {
  /** @format bigint */
  asset_id?: number
  /** @format numeric */
  delta?: number
  /** @format bigint */
  tenant_id?: number
}

export interface AggregatePositions {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format text */
  exchange?: string
  /** @format app_data.quantity */
  expected_pnl?: string
  /** @format app_data.quantity */
  mark_price?: string
  /** @format numeric */
  position?: number
  /** @format bigint */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
}

export interface Apis {
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  api_id?: number
  /**
   * Note: This is a Foreign Key to `credential_providers.id`.<fk
   * table='credential_providers' column='id'/>
   *
   * @format bigint
   */
  credential_provider_id?: number
  /** @format text */
  name?: string
}

export interface AssetBalances {
  /** @format bigint */
  asset_id?: number
  /** @format jsonb */
  balances?: any
  /** @format bigint */
  tenant_id?: number
}

export interface AssetFairs {
  /** @format bigint */
  asset_id?: number
  /** @format numeric */
  fair?: number
  /** @format app_data.fair_source */
  fair_source?: 'global' | 'fiat' | 'index'
  /** @format bigint */
  index_id?: number
  /** @format text */
  index_name?: string
  /** @format timestamp with time zone */
  updated_at?: string
}

export interface AssetInfo {
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format text */
  detailed_summary?: string
  /** @format app_data.fair_source */
  fair_source?: 'global' | 'fiat' | 'index'
  /** @format text[] */
  links?: string[]
  /** @format text */
  name?: string
  /**
   * Note: This is a Foreign Key to `indices.id`.<fk table='indices'
   * column='id'/>
   *
   * @format bigint
   */
  selected_index_id?: number
  /** @format text */
  short_summary?: string
  /** @format boolean */
  stablecoin?: boolean
  /** @format public.citext */
  ticker?: string
}

export interface AssetStats {
  /** @format bigint */
  asset_id?: number
  /** @format double precision */
  beta?: number
  /** @format double precision */
  downside_beta?: number
  /** @format double precision */
  upside_beta?: number
  /** @format numeric */
  volume?: number
}

export interface AssetTags {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id: number
  /**
   * Note: This is a Foreign Key to `tags.id`.<fk table='tags' column='id'/>
   *
   * @format bigint
   */
  tag_id: number
}

export interface AssetVol1D {
  /** @format bigint */
  asset_id?: number
  /** @format numeric */
  volatility?: number
}

export interface AssetVol1H {
  /** @format bigint */
  asset_id?: number
  /** @format numeric */
  volatility?: number
}

export interface AssetVol1W {
  /** @format bigint */
  asset_id?: number
  /** @format numeric */
  volatility?: number
}

export interface Assets {
  /** @format app_data.asset_class */
  asset_class?: 'fiat' | 'crypto' | 'token' | 'future' | 'swap'
  /** @format app_data.quantity */
  contract_size?: string
  /** @format boolean */
  expired?: boolean
  /** @format bigint */
  id?: number
  /** @format boolean */
  stablecoin?: boolean
  /** @format public.citext */
  ticker?: string
}

export interface Balances {
  /** @format bigint */
  asset_id?: number
  /** @format numeric */
  balance?: number
  /** @format bigint */
  exchange_id?: number
  /** @format numeric */
  free?: number
  /** @format bigint */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
}

export interface BorrowLimits {
  /** @format numeric */
  borrow_limit?: number
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `derivatives_fixed_expiry.tenant_id`.<fk table='derivatives_fixed_expiry'
   * column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `hot_wallets.id`.<fk table='hot_wallets' column='id'/>
   *
   * @format bigint
   */
  wallet_id?: number
}

export interface CancellationRequests {
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  created_by_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format bigint */
  order_id?: number
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
}

export interface CapitalConcentrationLimitUsage {
  /** @format app_data.quantity */
  aum?: string
  /** @format numeric */
  concentration_limit?: number
  /** @format double precision */
  concentration_proportion?: number
  /** @format numeric */
  concentration_usage?: number
  /** @format app_data.quantity */
  excess?: string
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `exchanges.id`.<fk table='exchanges' column='id'/>
   *
   * @format bigint
   */
  exchange_id?: number
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `derivatives_fixed_expiry.tenant_id`.<fk table='derivatives_fixed_expiry'
   * column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  updated_by_id?: number
}

export interface CapitalConcentrationLimits {
  /** @format numeric */
  concentration_limit?: number
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `exchanges.id`.<fk table='exchanges' column='id'/>
   *
   * @format bigint
   */
  exchange_id?: number
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `derivatives_fixed_expiry.tenant_id`.<fk table='derivatives_fixed_expiry'
   * column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  updated_by_id?: number
}

export interface CoinDeck {
  /** @format interval */
  age?: string
  /** @format numeric */
  change_24h?: number
  /** @format numeric */
  change_30m?: number
  /** @format numeric */
  change_4h?: number
  /** @format numeric */
  change_5m?: number
  /** @format numeric */
  fair?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format public.citext */
  ticker?: string
  /** @format numeric */
  volume?: number
}

export interface CoinDeckAssetTags {
  /** @format interval */
  age?: string
  /** @format numeric */
  change_24h?: number
  /** @format numeric */
  change_30m?: number
  /** @format numeric */
  change_4h?: number
  /** @format numeric */
  change_5m?: number
  /** @format numeric */
  fair?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format public.citext */
  tag?: string
  /** @format public.citext */
  ticker?: string
  /** @format numeric */
  volume?: number
}

export interface CoinDeckDerivatives {
  /** @format double precision */
  bps_per_day?: number
  /** @format double precision */
  days_to_expiry?: number
  /** @format bigint */
  derivative_id?: number
  /** @format bigint */
  exchange_id?: number
  /** @format text */
  expiry_class?: string
  /** @format numeric */
  fair?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  market_id?: number
  /** @format numeric */
  premium?: number
  /** @format numeric */
  swm?: number
  /** @format bigint */
  underlying_id?: number
  /** @format numeric */
  volume?: number
}

export interface CoinDeckOpenPredictions {
  /** @format interval */
  age?: string
  /** @format numeric */
  change_24h?: number
  /** @format numeric */
  change_30m?: number
  /** @format numeric */
  change_4h?: number
  /** @format numeric */
  change_5m?: number
  /** @format numeric */
  fair?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format public.citext */
  ticker?: string
  /** @format numeric */
  volume?: number
}

export interface ColdBalances {
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `asset_info.asset_id`.<fk table='asset_info' column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format numeric */
  balance?: number
  /**
   * Note: This is a Foreign Key to `exchanges.id`.<fk table='exchanges'
   * column='id'/>
   *
   * @format bigint
   */
  exchange_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format text
   */
  name?: string
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `derivatives_fixed_expiry.tenant_id`.<fk table='derivatives_fixed_expiry'
   * column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  updated_by_id?: number
}

export interface ContractRiskLimits {
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `asset_info.asset_id`.<fk table='asset_info' column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format smallint */
  leverage_limit?: number
  /** @format app_data.quantity */
  max_notional?: string
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `derivatives_fixed_expiry.tenant_id`.<fk table='derivatives_fixed_expiry'
   * column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
}

export interface CredentialProviders {
  /**
   * Note: This is a Foreign Key to `exchanges.id`.<fk table='exchanges'
   * column='id'/>
   *
   * @format bigint
   */
  exchange_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format text */
  name?: string
}

export interface CurrentIndexFairs {
  /** @format numeric */
  fair?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  index_id?: number
  /** @format timestamp with time zone */
  ts?: string
}

export interface DeltaLimitUsage {
  /** @format public.citext */
  asset?: string
  /** @format bigint */
  asset_id?: number
  /** @format numeric */
  aum_btc?: number
  /** @format boolean */
  breach?: boolean
  /** @format numeric */
  delta?: number
  /** @format numeric */
  delta_btc?: number
  /** @format numeric */
  delta_from_closest_limit?: number
  /** @format numeric */
  delta_from_limit_midpoint?: number
  /** @format text */
  delta_limit_asset_id?: string
  /** @format numeric */
  delta_limit_aum_proportion?: number
  /** @format numeric */
  delta_limit_lower?: number
  /** @format numeric */
  delta_limit_midpoint?: number
  /** @format numeric */
  delta_limit_proportion?: number
  /** @format numeric */
  delta_limit_upper?: number
  /** @format numeric */
  delta_ratio?: number
  /** @format numeric */
  delta_to_target?: number
  /** @format numeric */
  delta_to_target_btc?: number
  /** @format numeric */
  delta_to_target_ratio?: number
  /** @format text */
  note?: string
  /** @format app_data.quantity */
  target_delta?: string
  /** @format bigint */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
  /** @format bigint */
  updated_by_id?: number
  /** @format text */
  updated_by_name?: string
}

export interface DeltaLimits {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format numeric */
  delta_limit_lower?: number
  /** @format numeric */
  delta_limit_upper?: number
  /** @format text */
  note?: string
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  updated_by_id?: number
}

export interface Deltas {
  /** @format bigint */
  asset_id?: number
  /** @format numeric */
  delta?: number
  /** @format bigint */
  tenant_id?: number
}

export interface DerivativeMarketHoldings {
  /** @format numeric */
  delta?: number
  /** @format numeric */
  free_margin?: number
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  margin_asset_id?: number
  /** @format bigint */
  market_id?: number
  /** @format numeric */
  position?: number
  /** @format bigint */
  tenant_id?: number
  /** @format numeric */
  total_margin?: number
}

export interface Derivatives {
  /** @format app_data.asset_class */
  asset_class?: 'fiat' | 'crypto' | 'token' | 'future' | 'swap'
  /** @format app_data.quantity */
  contract_size?: string
  /** @format bigint */
  currency_id?: number
  /** @format bigint */
  derivative_id?: number
  /** @format bigint */
  exchange_id?: number
  /** @format timestamp with time zone */
  expiry?: string
  /** @format text */
  expiry_class?: string
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  market_id?: number
  /** @format app_data.quantity_t */
  quanto_multiplier?: string
  /** @format public.citext */
  ticker?: string
  /** @format bigint */
  underlying_id?: number
}

export interface DerivativesFixedExpiry {
  /** @format bigint */
  asset_id?: number
  /** @format numeric */
  bps_per_day?: number
  /** @format bigint */
  currency_id?: number
  /** @format numeric */
  days_to_expiry?: number
  /** @format text */
  derivative_class?: string
  /** @format bigint */
  exchange_id?: number
  /** @format timestamp with time zone */
  expiry?: string
  /** @format text */
  expiry_class?: string
  /** @format numeric */
  fair?: number
  /**
   * Note: This is a Foreign Key to `coin_deck_derivatives.market_id`.<fk
   * table='coin_deck_derivatives' column='market_id'/>
   *
   * @format bigint
   */
  hedge_market_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  market_id?: number
  /** @format numeric */
  position?: number
  /** @format numeric */
  position_usd?: number
  /** @format numeric */
  premium_bps?: number
  /** @format numeric */
  premium_change_1d_bps?: number
  /** @format numeric */
  premium_change_1h_bps?: number
  /** @format numeric */
  premium_change_3d_bps?: number
  /** @format numeric */
  premium_change_7d_bps?: number
  /** @format boolean */
  premium_uses_single_hedge_market?: boolean
  /** @format numeric */
  swm?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format bigint */
  underlying_id?: number
  /** @format numeric */
  volume?: number
}

export interface ExchangeBalances {
  /** @format numeric */
  balance?: number
  /** @format text */
  exchange?: string
  /** @format bigint */
  exchange_id?: number
  /** @format bigint */
  tenant_id?: number
}

export interface Exchanges {
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format text */
  name?: string
}

export interface ExpectedNavs {
  /** @format app_data.quantity */
  expected_nav?: string
  /** @format bigint */
  tenant_id?: number
}

export interface Fairs {
  /** @format bigint */
  asset_id?: number
  /** @format numeric */
  fair?: number
}

export interface Fills {
  /** @format text */
  creator?: string
  /** @format app_data.direction */
  direction?: 'buy' | 'sell'
  /** @format numeric */
  fair?: number
  /** @format numeric */
  fair_delta_bps?: number
  /** @format numeric */
  fee?: number
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  fee_currency_id?: number
  /** @format timestamp with time zone */
  filled_at?: string
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /**
   * Note: This is a Foreign Key to `coin_deck_derivatives.market_id`.<fk
   * table='coin_deck_derivatives' column='market_id'/>
   *
   * @format bigint
   */
  market_id?: number
  /** @format bigint */
  order_id?: number
  /** @format numeric */
  price?: number
  /** @format numeric */
  quantity?: number
  /** @format interval */
  scrape_latency?: string
  /** @format app_data.trade_source */
  source?: 'manual' | 'hedge' | 'twap'
  /** @format text */
  strategy?: string
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format bigint */
  underlying_id?: number
}

export interface FireblocksAccountRules {
  /** @format bigint */
  dest_account_id?: number
  /** @format app_data.allow_deny */
  permission?: 'allow' | 'deny'
  /** @format bigint */
  source_account_id?: number
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
}

export interface FireblocksAccounts {
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  account_id?: number
  /** @format text */
  account_name?: string
  /** @format fireblocks.account_type */
  account_type?:
    | 'vault'
    | 'exchange'
    | 'fiat'
    | 'internal'
    | 'external'
    | 'network'
    | 'compound'
    | 'one_time_address'
  /** @format jsonb */
  asset_balances?: any
  /** @format text */
  exchange?: string
  /**
   * Note: This is a Foreign Key to `exchanges.id`.<fk table='exchanges'
   * column='id'/>
   *
   * @format bigint
   */
  exchange_id?: number
  /** @format text */
  fireblocks_id?: string
  /** @format boolean */
  is_subaccount?: boolean
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
}

export interface FireblocksAllowedDestAccounts {
  /** @format bigint */
  dest_account_id?: number
  /** @format bigint */
  source_account_id?: number
  /** @format bigint */
  tenant_id?: number
}

export interface FireblocksBalances {
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `fireblocks_accounts.account_id`.<fk table='fireblocks_accounts'
   * column='account_id'/>
   *
   * @format bigint
   */
  account_id?: number
  /** @format text */
  account_name?: string
  /** @format fireblocks.account_type */
  account_type?:
    | 'vault'
    | 'exchange'
    | 'fiat'
    | 'internal'
    | 'external'
    | 'network'
    | 'compound'
    | 'one_time_address'
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `asset_info.asset_id`.<fk table='asset_info' column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format numeric */
  balance?: number
  /** @format text */
  blockchain?: string
  /** @format text */
  exchange?: string
  /**
   * Note: This is a Foreign Key to `exchanges.id`.<fk table='exchanges'
   * column='id'/>
   *
   * @format bigint
   */
  exchange_id?: number
  /** @format numeric */
  free?: number
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format public.citext */
  ticker?: string
}

export interface FireblocksKnownAccountAssets {
  /** @format bigint */
  account_id?: number
  /** @format bigint[] */
  asset_ids?: number[]
}

export interface FireblocksSupportedAssets {
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format text */
  contract_address?: string
  /** @format bigint */
  decimals?: number
  /** @format text */
  name?: string
  /** @format text */
  native_asset?: string
  /** @format text */
  type?: string
}

export interface FireblocksTransactionRequests {
  /** @format numeric */
  amount?: number
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  created_by_id?: number
  /**
   * Note: This is a Foreign Key to `fireblocks_accounts.account_id`.<fk
   * table='fireblocks_accounts' column='account_id'/>
   *
   * @format bigint
   */
  dest_account_id?: number
  /** @format numeric */
  fee?: number
  /** @format fireblocks.fee_level */
  fee_level?: 'low' | 'medium' | 'high'
  /** @format numeric */
  gas_price?: number
  /** @format text */
  note?: string
  /**
   * Note: This is a Foreign Key to `fireblocks_accounts.account_id`.<fk
   * table='fireblocks_accounts' column='account_id'/>
   *
   * @format bigint
   */
  source_account_id?: number
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  transaction_request_id?: number
}

export interface FireblocksTransactions {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format public.citext */
  asset_ticker?: string
  /** @format text */
  blockchain?: string
  /** @format timestamp with time zone */
  created_at?: string
  /** @format text */
  created_by?: string
  /** @format text */
  dest_account?: string
  /**
   * Note: This is a Foreign Key to `fireblocks_accounts.account_id`.<fk
   * table='fireblocks_accounts' column='account_id'/>
   *
   * @format bigint
   */
  dest_account_id?: number
  /** @format text */
  dest_exchange?: string
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  fee_currency_id?: number
  /** @format text */
  fireblocks_id?: string
  /** @format numeric */
  net_amount?: number
  /** @format numeric */
  network_fee?: number
  /** @format text */
  note?: string
  /** @format text */
  rejected_by?: string
  /** @format numeric */
  requested_amount?: number
  /** @format timestamp with time zone */
  scraped_at?: string
  /** @format numeric */
  service_fee?: number
  /** @format text */
  source_account?: string
  /**
   * Note: This is a Foreign Key to `fireblocks_accounts.account_id`.<fk
   * table='fireblocks_accounts' column='account_id'/>
   *
   * @format bigint
   */
  source_account_id?: number
  /** @format text */
  source_exchange?: string
  /** @format text */
  status?: string
  /** @format text */
  status_description?: string
  /** @format boolean */
  status_is_closed?: boolean
  /** @format text */
  sub_status?: string
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format text */
  transaction_hash?: string
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  transaction_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
}

export interface FundingRates {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format text */
  derivative_class?: string
  /** @format text */
  exchange?: string
  /**
   * Note: This is a Foreign Key to `exchanges.id`.<fk table='exchanges'
   * column='id'/>
   *
   * @format bigint
   */
  exchange_id?: number
  /** @format bigint */
  hedge_market_id?: number
  /** @format numeric */
  indicative_funding_rate_bps?: number
  /** @format timestamp with time zone */
  indicative_funding_rate_funding_event_ts?: string
  /** @format timestamp with time zone */
  indicative_funding_rate_ts?: string
  /** @format bigint */
  margin_limit_tenant_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  market_id?: number
  /** @format numeric */
  next_funding_rate_bps?: number
  /** @format timestamp with time zone */
  next_funding_rate_ts?: string
  /** @format bigint */
  num_component_rates_1d?: number
  /** @format bigint */
  num_component_rates_3d?: number
  /** @format bigint */
  num_component_rates_7d?: number
  /** @format numeric */
  position?: number
  /** @format numeric */
  position_usd?: number
  /** @format numeric */
  premium_bps?: number
  /** @format numeric */
  reference_margin_limit?: number
  /** @format numeric */
  roc_1d?: number
  /** @format numeric */
  roc_3d?: number
  /** @format numeric */
  roc_7d?: number
  /** @format numeric */
  roc_multiplier?: number
  /** @format numeric */
  spot_ask_bps?: number
  /** @format numeric */
  spot_bid_bps?: number
  /** @format numeric */
  swap_ask_bps?: number
  /** @format numeric */
  swap_bid_bps?: number
  /** @format public.citext */
  symbol?: string
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format numeric */
  total_1d_bps?: number
  /** @format numeric */
  total_3d_bps?: number
  /** @format numeric */
  total_7d_bps?: number
  /** @format bigint */
  underlying_id?: number
  /** @format numeric */
  volume?: number
}

export interface HedgeRequests {
  /** @format integer */
  delay?: number
  /** @format app_data.margin_mode */
  margin_mode?: 'spot' | 'margin' | 'borrow' | 'repay'
  /**
   * Note: This is a Foreign Key to `coin_deck_derivatives.market_id`.<fk
   * table='coin_deck_derivatives' column='market_id'/>
   *
   * @format bigint
   */
  market_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  trade_request_id?: number
}

export interface Hedges {
  /** @format bigint */
  asset_id?: number
  /** @format double precision */
  beta?: number
  /** @format numeric */
  btc_delta?: number
  /** @format numeric */
  delta?: number
  /** @format double precision */
  downside_beta?: number
  /** @format double precision */
  downside_hedge?: number
  /** @format double precision */
  hedge?: number
  /** @format bigint */
  tenant_id?: number
  /** @format double precision */
  upside_beta?: number
  /** @format double precision */
  upside_hedge?: number
}

export interface HotWallets {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format boolean */
  disabled?: boolean
  /**
   * Note: This is a Foreign Key to `exchanges.id`.<fk table='exchanges'
   * column='id'/>
   *
   * @format bigint
   */
  exchange_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format public.citext */
  name?: string
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format app_data.wallet_type */
  wallet_type?: 'transfer' | 'spot' | 'margin' | 'futures' | 'swaps' | 'lending'
}

export interface Indices {
  /** @format boolean */
  active?: boolean
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  created_by_id?: number
  /** @format text */
  definition?: string
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format text */
  name?: string
  /** @format timestamp with time zone */
  updated_at?: string
}

export interface IndicesMonitoring {
  /** @format numrange */
  alert_outside_of_range?: string
  /** @format text */
  definition?: string
  /** @format numeric */
  fair?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  index_id?: number
  /** @format text */
  index_name?: string
  /** @format boolean */
  is_alert?: boolean
  /** @format interval */
  mute_for?: string
  /**
   * Note: This is a Foreign Key to `slack_targets.target`.<fk
   * table='slack_targets' column='target'/>
   *
   * @format text
   */
  target?: string
}

export interface IndicesMonitoringThresholds {
  /** @format numrange */
  alert_outside_of_range?: string
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  index_id?: number
  /** @format interval */
  mute_for?: string
  /**
   * Note: This is a Foreign Key to `slack_targets.target`.<fk
   * table='slack_targets' column='target'/>
   *
   * @format text
   */
  target?: string
}

export interface LeverageLimitChanges {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  created_by_id?: number
  /** @format smallint */
  leverage_limit?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format integer
   */
  leverage_limit_change_id?: number
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
}

export interface MarginAccounts {
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `exchanges.id`.<fk table='exchanges' column='id'/>
   *
   * @format bigint
   */
  exchange_id?: number
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `derivatives_fixed_expiry.tenant_id`.<fk table='derivatives_fixed_expiry'
   * column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format app_data.quantity */
  total_collateral?: string
  /** @format timestamp with time zone */
  updated_at?: string
}

export interface MarginBalances {
  /** @format numeric */
  balance?: number
  /** @format numeric */
  borrowed?: number
  /** @format numeric */
  free?: number
  /** @format numeric */
  interest?: number
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  wallet_id?: number
}

export interface MarginLimitUsage {
  /** @format app_data.quantity */
  balance?: string
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  exchange_id?: number
  /** @format app_data.quantity */
  exposure?: string
  /** @format app_data.quantity */
  long_exposure?: string
  /** @format numeric */
  lower_limit_long?: number
  /** @format numeric */
  lower_limit_short?: number
  /** @format double precision */
  margin_limit_proportion?: number
  /** @format double precision */
  margin_limit_proportion_long?: number
  /** @format double precision */
  margin_limit_proportion_short?: number
  /** @format numeric */
  margin_ratio?: number
  /** @format numeric */
  margin_ratio_long?: number
  /** @format numeric */
  margin_ratio_short?: number
  /** @format text */
  note?: string
  /** @format app_data.quantity */
  short_exposure?: string
  /** @format bigint */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  updated_by_id?: number
  /** @format numeric */
  upper_limit_long?: number
  /** @format numeric */
  upper_limit_short?: number
  /** @format bigint */
  wallet_id?: number
  /** @format public.citext */
  wallet_name?: string
  /** @format app_data.wallet_type */
  wallet_type?: 'transfer' | 'spot' | 'margin' | 'futures' | 'swaps' | 'lending'
  /** @format app_data.quantity */
  worst_exposure?: string
  /** @format text */
  worst_limit_direction?: string
  /** @format numeric */
  worst_lower_limit?: number
  /** @format double precision */
  worst_margin_limit_proportion?: number
  /** @format numeric */
  worst_margin_ratio?: number
}

export interface MarginLimits {
  /** @format numeric */
  lower_limit_long?: number
  /** @format numeric */
  lower_limit_short?: number
  /** @format text */
  note?: string
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  updated_by_id?: number
  /** @format numeric */
  upper_limit_long?: number
  /** @format numeric */
  upper_limit_short?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  wallet_id?: number
}

export interface MarginMarketHoldings {
  /** @format numeric */
  delta?: number
  /** @format numeric */
  free_asset_balance?: number
  /** @format numeric */
  free_currency_balance?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  market_id?: number
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format numeric */
  total_asset_balance?: number
  /** @format numeric */
  total_currency_balance?: number
}

export interface MarginOverview {
  /** @format app_data.quantity */
  balance?: string
  /** @format app_data.quantity */
  debt?: string
  /**
   * Note: This is a Foreign Key to `exchanges.id`.<fk table='exchanges'
   * column='id'/>
   *
   * @format bigint
   */
  exchange_id?: number
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
}

export interface MarginRequests {
  /** @format numeric */
  amount?: number
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  created_by_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  margin_request_id?: number
  /** @format app_data.margin_event */
  request_type?: 'borrow' | 'repay'
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format bigint */
  wallet_id?: number
}

export interface MarketProperties {
  /** @format numeric */
  lot_size?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  market_id?: number
  /** @format numeric */
  min_notional?: number
  /** @format numeric */
  tick_size?: number
  /** @format numeric */
  tick_size_as_fixed?: number
  /** @format app_data.tick_size_style */
  tick_size_style?: 'fixed' | 'digits' | 'significant_figures'
}

export interface MarketSummaries {
  /** @format numeric */
  ask_price?: number
  /** @format numeric */
  ask_size?: number
  /** @format numeric */
  bid_price?: number
  /** @format numeric */
  bid_size?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format bigint */
  price_currency_id?: number
  /** @format numeric */
  swm?: number
  /** @format bigint */
  underlying_id?: number
  /** @format numeric */
  volume?: number
}

export interface Markets {
  /** @format text */
  api_name?: string
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format bigint */
  collateral_id?: number
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  currency_id?: number
  /** @format boolean */
  disabled?: boolean
  /** @format text */
  exchange?: string
  /** @format boolean */
  expired?: boolean
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format numeric */
  lot_size?: number
  /** @format numeric */
  min_notional?: number
  /** @format bigint */
  price_currency_id?: number
  /** @format smallint */
  price_precision?: number
  /** @format smallint */
  quantity_precision?: number
  /** @format numeric */
  tick_size?: number
  /** @format bigint */
  underlying_id?: number
  /** @format numeric */
  volume?: number
}

export interface OpenOrders {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  currency_id?: number
  /** @format app_data.direction */
  direction?: 'buy' | 'sell'
  /** @format numeric */
  filled_quantity?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format text */
  id_on_exchange?: string
  /**
   * Note: This is a Foreign Key to `coin_deck_derivatives.market_id`.<fk
   * table='coin_deck_derivatives' column='market_id'/>
   *
   * @format bigint
   */
  market_id?: number
  /** @format numeric */
  price?: number
  /** @format numeric */
  quantity?: number
  /** @format app_data.order_status */
  status?: 'open' | 'closed'
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format bigint */
  trade_request_id?: number
}

export interface OrderHedgeStatus {
  /** @format boolean */
  any_hedge_order_open?: boolean
  /** @format numeric */
  avg_filled_price?: number
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  created_by_id?: number
  /** @format app_data.direction */
  direction?: 'buy' | 'sell'
  /** @format text */
  error?: string
  /** @format numeric */
  filled_quantity?: number
  /** @format numeric */
  hedge_avg_filled_price?: number
  /** @format numeric */
  hedge_avg_order_price?: number
  /** @format text[] */
  hedge_errors?: string[]
  /** @format numeric */
  hedge_filled_quantity?: number
  /**
   * Note: This is a Foreign Key to `coin_deck_derivatives.market_id`.<fk
   * table='coin_deck_derivatives' column='market_id'/>
   *
   * @format bigint
   */
  hedge_market_id?: number
  /** @format numeric */
  hedge_order_quantity?: number
  /**
   * Note: This is a Foreign Key to `coin_deck_derivatives.market_id`.<fk
   * table='coin_deck_derivatives' column='market_id'/>
   *
   * @format bigint
   */
  market_id?: number
  /** @format bigint */
  num_fills?: number
  /** @format bigint */
  num_hedges_requested?: number
  /** @format app_data.order_status */
  order_status?: 'open' | 'closed'
  /** @format numeric */
  price?: number
  /** @format numeric */
  quantity?: number
  /** @format app_data.trade_source */
  source?: 'manual' | 'hedge' | 'twap'
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  trade_request_id?: number
}

export interface Orders {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  currency_id?: number
  /** @format app_data.direction */
  direction?: 'buy' | 'sell'
  /** @format numeric */
  filled_quantity?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format text */
  id_on_exchange?: string
  /**
   * Note: This is a Foreign Key to `coin_deck_derivatives.market_id`.<fk
   * table='coin_deck_derivatives' column='market_id'/>
   *
   * @format bigint
   */
  market_id?: number
  /** @format numeric */
  price?: number
  /** @format numeric */
  quantity?: number
  /** @format app_data.order_status */
  status?: 'open' | 'closed'
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format bigint */
  trade_request_id?: number
}

export interface Ping {
  /** @format text */
  response?: string
}

export interface PositionUpnl {
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `asset_info.asset_id`.<fk table='asset_info' column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format bigint */
  currency_id?: number
  /** @format numeric */
  derived_upnl?: number
  /** @format timestamp with time zone */
  derived_upnl_updated_at?: string
  /** @format numeric */
  entry_price?: number
  /** @format text */
  exchange?: string
  /** @format numeric */
  exchange_upnl?: number
  /** @format timestamp with time zone */
  exchange_upnl_updated_at?: string
  /** @format numeric */
  position?: number
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `derivatives_fixed_expiry.tenant_id`.<fk table='derivatives_fixed_expiry'
   * column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
}

export interface Positions {
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `asset_info.asset_id`.<fk table='asset_info' column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format text */
  exchange?: string
  /** @format app_data.quantity_t */
  expected_pnl?: string
  /** @format app_data.quantity_t */
  mark_price?: string
  /** @format numeric */
  position?: number
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `derivatives_fixed_expiry.tenant_id`.<fk table='derivatives_fixed_expiry'
   * column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
}

export interface PredictionApprovals {
  /** @format boolean */
  approved?: boolean
  /** @format timestamp with time zone */
  approved_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  approved_by_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  prediction_id?: number
}

export interface PredictionComments {
  /** @format text */
  author?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  author_id?: number
  /** @format text */
  body?: string
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /**
   * Note: This is a Foreign Key to `predictions.id`.<fk table='predictions'
   * column='id'/>
   *
   * @format bigint
   */
  prediction_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
}

export interface PredictionExport {
  /** @format numeric */
  actual_move?: number
  /** @format boolean */
  approved?: boolean
  /** @format public.citext */
  asset?: string
  /** @format public.citext */
  base?: string
  /** @format numeric */
  close_fair?: number
  /** @format timestamp with time zone */
  closed_at?: string
  /** @format text */
  comment?: string
  /** @format integer */
  confidence?: number
  /** @format timestamp with time zone */
  created_at?: string
  /** @format numeric */
  expected_move?: number
  /** @format timestamp with time zone */
  expires_at?: string
  /** @format numeric */
  profit?: number
  /** @format numeric */
  size?: number
  /** @format numeric */
  start_fair?: number
  /** @format numeric */
  stop_loss?: number
  /** @format numeric */
  take_profit?: number
  /** @format text */
  username?: string
}

export interface Predictions {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  base_id?: number
  /** @format numeric */
  close_fair?: number
  /** @format timestamp with time zone */
  closed_at?: string
  /** @format text */
  comment?: string
  /** @format integer */
  confidence?: number
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  created_by_id?: number
  /** @format interval */
  duration?: string
  /** @format numeric */
  expected_move?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format boolean */
  not_tradeable?: boolean
  /** @format numeric */
  size?: number
  /** @format numeric */
  start_fair?: number
  /** @format numeric */
  stop_loss?: number
  /** @format numeric */
  take_profit?: number
  /** @format boolean */
  traded?: boolean
  /** @format timestamp with time zone */
  updated_at?: string
}

export interface PredictionsPnl {
  /** @format numeric */
  actual_move?: number
  /** @format boolean */
  approved?: boolean
  /** @format bigint */
  asset_id?: number
  /** @format bigint */
  base_id?: number
  /** @format numeric */
  close_fair?: number
  /** @format boolean */
  closeable?: boolean
  /** @format timestamp with time zone */
  closed_at?: string
  /** @format text */
  comment?: string
  /** @format bigint */
  comments?: number
  /** @format integer */
  confidence?: number
  /** @format timestamp with time zone */
  created_at?: string
  /** @format text */
  created_by?: string
  /** @format numeric */
  expected_move?: number
  /** @format timestamp with time zone */
  expires_at?: string
  /** @format bigint */
  fills?: number
  /** @format bigint */
  id?: number
  /** @format boolean */
  open?: boolean
  /** @format numeric */
  profit?: number
  /** @format numeric */
  size?: number
  /** @format numeric */
  start_fair?: number
  /** @format numeric */
  stop_loss?: number
  /** @format numeric */
  take_profit?: number
  /** @format bigint */
  trade_group_id?: number
}

export interface QuotePremia {
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  hedge_market_id?: number
  /** @format bigint */
  market_id?: number
  /** @format numeric */
  premium?: number
  /** @format numeric */
  spot_ask_price?: number
  /** @format numeric */
  spot_bid_price?: number
  /** @format numeric */
  swap_ask_price?: number
  /** @format numeric */
  swap_bid_price?: number
  /** @format timestamp with time zone */
  ts?: string
}

export interface RecentCumulativeFunding {
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format bigint */
  num_component_rates_1d?: number
  /** @format bigint */
  num_component_rates_3d?: number
  /** @format bigint */
  num_component_rates_7d?: number
  /** @format public.citext */
  symbol?: string
  /** @format numeric */
  total_1d?: number
  /** @format numeric */
  total_3d?: number
  /** @format numeric */
  total_7d?: number
}

export interface RecentTwaps {
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  creator_id?: number
  /** @format interval */
  duration?: string
  /** @format timestamp with time zone */
  ended_at?: string
  /** @format interval */
  frequency?: string
  /** @format bigint[] */
  market_ids?: number[]
  /** @format integer */
  num_orders?: number
  /** @format timestamp with time zone */
  started_at?: string
  /** @format algo.twap_status */
  status?:
    | 'planning'
    | 'starting'
    | 'running'
    | 'pausing'
    | 'paused'
    | 'cancelling'
    | 'cancelled'
    | 'aborted'
    | 'completed'
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  twap_id?: number
  /** @format bigint */
  underlying_id?: number
}

export interface RecommendedSizes {
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format numeric */
  recommended_size?: number
  /** @format bigint */
  tenant_id?: number
}

export interface SlackTargets {
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format text
   */
  target?: string
}

export interface SpotMarketHoldings {
  /** @format numeric */
  delta?: number
  /** @format numeric */
  free_asset_balance?: number
  /** @format numeric */
  free_currency_balance?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  market_id?: number
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format numeric */
  total_asset_balance?: number
  /** @format numeric */
  total_currency_balance?: number
}

export interface Strategies {
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format text */
  name?: string
}

export interface Tags {
  /**
   * @default 'CURRENT_TIMESTAMP'
   * @format timestamp with time zone
   */
  created_at: string
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id: number
  /** @format public.citext */
  name: string
}

export interface TargetDeltas {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format app_data.quantity */
  delta?: string
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format text */
  name?: string
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  updated_by_id?: number
}

export interface TenantCredentials {
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `credential_providers.id`.<fk table='credential_providers' column='id'/>
   *
   * @format bigint
   */
  api_id?: number
  /** @format text */
  api_key?: string
  /** @format text */
  api_secret?: string
  /** @format timestamp with time zone */
  created_at?: string
  /** @format text */
  password?: string
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `derivatives_fixed_expiry.tenant_id`.<fk table='derivatives_fixed_expiry'
   * column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format text */
  username?: string
}

export interface TenantDescendants {
  /** @format bigint[] */
  descendant_ids?: number[]
  /** @format bigint */
  tenant_id?: number
}

export interface Tenants {
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format text */
  name?: string
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  parent_id?: number
}

export interface TradeGroupFills {
  /** @format app_data.direction */
  direction?: 'buy' | 'sell'
  /** @format timestamp with time zone */
  end_time?: string
  /** @format bigint */
  market_id?: number
  /** @format numeric */
  price?: number
  /** @format numeric */
  quantity?: number
  /** @format timestamp with time zone */
  start_time?: string
  /**
   * Note: This is a Foreign Key to `trade_groups.id`.<fk table='trade_groups'
   * column='id'/>
   *
   * @format bigint
   */
  trade_group_id?: number
}

export interface TradeGroups {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format timestamp with time zone */
  created_at?: string
  /** @format numeric */
  expected_move?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format boolean */
  open?: boolean
  /** @format bigint */
  open_predictions?: number
  /** @format numeric */
  profit?: number
  /** @format app_data.quantity */
  stop_loss_price?: string
  /** @format app_data.quantity */
  take_profit_price?: string
}

export interface TradeRequestStatus {
  /** @format text[] */
  errors?: string[]
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format bigint */
  orders?: number
  /** @format text */
  status?: string
}

export interface TradeRequests {
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  created_by_id?: number
  /** @format app_data.direction */
  direction?: 'buy' | 'sell'
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format app_data.margin_mode */
  margin_mode?: 'spot' | 'margin' | 'borrow' | 'repay'
  /**
   * Note: This is a Foreign Key to `coin_deck_derivatives.market_id`.<fk
   * table='coin_deck_derivatives' column='market_id'/>
   *
   * @format bigint
   */
  market_id?: number
  /** @format boolean */
  post_only?: boolean
  /** @format numeric */
  price?: number
  /** @format numeric */
  quantity?: number
  /** @format app_data.trade_source */
  source?: 'manual' | 'hedge' | 'twap'
  /**
   * Note: This is a Foreign Key to `strategies.id`.<fk table='strategies'
   * column='id'/>
   *
   * @format bigint
   */
  strategy_id?: number
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format app_data.time_in_force */
  time_in_force?: 'GTC' | 'IOC' | 'FOK' | 'MKT' | 'GTT'
}

export interface TransferRequests {
  /** @format numeric */
  amount?: number
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  created_by_id?: number
  /** @format bigint */
  from_wallet_id?: number
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format bigint */
  to_wallet_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  transfer_request_id?: number
}

export interface Transfers {
  /** @format numeric */
  amount?: number
  /** @format bigint */
  asset_id?: number
  /** @format text */
  from_exchange?: string
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format timestamp with time zone */
  received_at?: string
  /** @format timestamp with time zone */
  sent_at?: string
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format text */
  to_exchange?: string
}

export interface TwapLegs {
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  creator_id?: number
  /** @format app_data.direction */
  direction?: 'buy' | 'sell'
  /** @format algo.execution_style */
  execution_style?: 'take' | 'provide'
  /** @format interval */
  gtt_duration?: string
  /** @format app_data.margin_mode */
  hedge_margin_mode?: 'spot' | 'margin' | 'borrow' | 'repay'
  /**
   * Note: This is a Foreign Key to `coin_deck_derivatives.market_id`.<fk
   * table='coin_deck_derivatives' column='market_id'/>
   *
   * @format bigint
   */
  hedge_market_id?: number
  /** @format app_data.margin_mode */
  margin_mode?: 'spot' | 'margin' | 'borrow' | 'repay'
  /**
   * Note: This is a Foreign Key to `coin_deck_derivatives.market_id`.<fk
   * table='coin_deck_derivatives' column='market_id'/>
   *
   * @format bigint
   */
  market_id?: number
  /** @format numeric */
  quantity?: number
  /** @format bigint */
  tenant_id?: number
  /** @format bigint */
  twap_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  twap_leg_id?: number
}

export interface TwapTradeRequestErrors {
  /** @format text */
  error?: string
  /**
   * Note: This is a Foreign Key to `twap_legs.twap_leg_id`.<fk
   * table='twap_legs' column='twap_leg_id'/>
   *
   * @format bigint
   */
  twap_leg_id?: number
}

export interface TwapTradeRequests {
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `order_hedge_status.trade_request_id`.<fk table='order_hedge_status'
   * column='trade_request_id'/>
   *
   * @format bigint
   */
  trade_request_id?: number
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `twap_legs.twap_leg_id`.<fk table='twap_legs' column='twap_leg_id'/>
   *
   * @format bigint
   */
  twap_leg_id?: number
}

export interface Twaps {
  /** @format timestamp with time zone */
  created_at?: string
  /**
   * Note: This is a Foreign Key to `users.id`.<fk table='users' column='id'/>
   *
   * @format bigint
   */
  creator_id?: number
  /** @format interval */
  duration?: string
  /** @format timestamp with time zone */
  ended_at?: string
  /** @format interval */
  frequency?: string
  /** @format integer */
  max_slippage?: number
  /** @format timestamp with time zone */
  started_at?: string
  /** @format algo.twap_status */
  status?:
    | 'planning'
    | 'starting'
    | 'running'
    | 'pausing'
    | 'paused'
    | 'cancelling'
    | 'cancelled'
    | 'aborted'
    | 'completed'
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  twap_id?: number
}

export interface UncollateralizedPositions {
  /** @format bigint */
  asset_id?: number
  /** @format bigint */
  exchange_id?: number
  /** @format numeric */
  position?: number
  /** @format bigint */
  tenant_id?: number
}

export interface Upnl {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format numeric */
  balance?: number
  /** @format text */
  exchange?: string
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
  /** @format numeric */
  upnl?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  wallet_id?: number
  /** @format public.citext */
  wallet_name?: string
  /** @format app_data.wallet_type */
  wallet_type?: 'transfer' | 'spot' | 'margin' | 'futures' | 'swaps' | 'lending'
}

export interface UserTenants {
  /** @format app_data.tenant_role */
  role?: 'admin' | 'senior_trader' | 'trader' | 'trading_ops' | 'readonly'
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to
   * `derivatives_fixed_expiry.tenant_id`.<fk table='derivatives_fixed_expiry'
   * column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /**
   * Note: This is a Primary Key.<pk/> This is a Foreign Key to `users.id`.<fk
   * table='users' column='id'/>
   *
   * @format bigint
   */
  user_id?: number
}

export interface Users {
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  id?: number
  /** @format text */
  name?: string
}

export interface WalletBalances {
  /** @format numeric */
  balance?: number
  /** @format numeric */
  free?: number
  /** @format bigint */
  tenant_id?: number
  /** @format timestamp with time zone */
  updated_at?: string
  /** @format bigint */
  wallet_id?: number
}

export interface WalletUpnl {
  /**
   * Note: This is a Foreign Key to `asset_info.asset_id`.<fk table='asset_info'
   * column='asset_id'/>
   *
   * @format bigint
   */
  asset_id?: number
  /** @format numeric */
  balance?: number
  /** @format numeric */
  derived_upnl?: number
  /** @format timestamp with time zone */
  derived_upnl_updated_at?: string
  /** @format text */
  exchange?: string
  /** @format numeric */
  exchange_upnl?: number
  /** @format timestamp with time zone */
  exchange_upnl_updated_at?: string
  /**
   * Note: This is a Foreign Key to `derivatives_fixed_expiry.tenant_id`.<fk
   * table='derivatives_fixed_expiry' column='tenant_id'/>
   *
   * @format bigint
   */
  tenant_id?: number
  /**
   * Note: This is a Primary Key.<pk/>
   *
   * @format bigint
   */
  wallet_id?: number
  /** @format public.citext */
  wallet_name?: string
  /** @format app_data.wallet_type */
  wallet_type?: 'transfer' | 'spot' | 'margin' | 'futures' | 'swaps' | 'lending'
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** Set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** Request path */
  path: string
  /** Content type of request body */
  type?: ContentType
  /** Query params */
  query?: QueryParamsType
  /** Format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** Request body */
  body?: unknown
  /** Base url */
  baseUrl?: string
  /** Request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'https://api.tail6f62.ts.net:443'
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter(
      key => 'undefined' !== typeof query[key],
    )
    return keys
      .map(key =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string'
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams,
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected createAbortSignal = (
    cancelToken: CancelToken,
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {}),
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body),
      },
    ).then(async response => {
      const r = response.clone() as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then(data => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch(e => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data.data
    })
  }
}

/**
 * @version 11.1.0 (1f13e43)
 * @title PostgREST API
 * @baseUrl https://api.tail6f62.ts.net:443
 * @externalDocs https://postgrest.org/en/v11.1/api.html
 * This is a dynamic API generated by PostgREST
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Introspection
   * @name GetRoot
   * @summary OpenAPI description (this document)
   * @request GET:/
   * @response `200` `unknown` OK
   */
  getRoot = (params: RequestParams = {}) =>
    this.request<unknown, any>({
      path: `/`,
      method: 'GET',
      ...params,
    })

  aggregateBalances = {
    /**
     * No description
     *
     * @tags aggregate_balances
     * @name AggregateBalancesList
     * @request GET:/aggregate_balances
     * @response `200` `(AggregateBalances)[]` OK
     * @response `206` `unknown` Partial Content
     */
    aggregateBalancesList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        balance?: string
        /** @format numeric */
        free?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<AggregateBalances[], any>({
        path: `/aggregate_balances`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  aggregateColdBalances = {
    /**
     * No description
     *
     * @tags aggregate_cold_balances
     * @name AggregateColdBalancesList
     * @request GET:/aggregate_cold_balances
     * @response `200` `(AggregateColdBalances)[]` OK
     * @response `206` `unknown` Partial Content
     */
    aggregateColdBalancesList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        balance?: string
        /** @format bigint */
        exchange_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<AggregateColdBalances[], any>({
        path: `/aggregate_cold_balances`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  aggregateDeltas = {
    /**
     * No description
     *
     * @tags aggregate_deltas
     * @name AggregateDeltasList
     * @request GET:/aggregate_deltas
     * @response `200` `(AggregateDeltas)[]` OK
     * @response `206` `unknown` Partial Content
     */
    aggregateDeltasList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        delta?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<AggregateDeltas[], any>({
        path: `/aggregate_deltas`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  aggregatePositions = {
    /**
     * No description
     *
     * @tags aggregate_positions
     * @name AggregatePositionsList
     * @request GET:/aggregate_positions
     * @response `200` `(AggregatePositions)[]` OK
     * @response `206` `unknown` Partial Content
     */
    aggregatePositionsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        position?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format text */
        exchange?: string
        /** @format app_data.quantity */
        mark_price?: string
        /** @format app_data.quantity */
        expected_pnl?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<AggregatePositions[], any>({
        path: `/aggregate_positions`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  apis = {
    /**
     * No description
     *
     * @tags apis
     * @name ApisDelete
     * @request DELETE:/apis
     * @response `204` `unknown` No Content
     */
    apisDelete: (
      query?: {
        /** @format bigint */
        api_id?: string
        /** @format bigint */
        credential_provider_id?: string
        /** @format text */
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/apis`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags apis
     * @name ApisList
     * @request GET:/apis
     * @response `200` `(Apis)[]` OK
     * @response `206` `unknown` Partial Content
     */
    apisList: (
      query?: {
        /** @format bigint */
        api_id?: string
        /** @format bigint */
        credential_provider_id?: string
        /** @format text */
        name?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Apis[], any>({
        path: `/apis`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags apis
     * @name ApisPartialUpdate
     * @request PATCH:/apis
     * @response `204` `unknown` No Content
     */
    apisPartialUpdate: (
      apis: Apis,
      query?: {
        /** @format bigint */
        api_id?: string
        /** @format bigint */
        credential_provider_id?: string
        /** @format text */
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/apis`,
        method: 'PATCH',
        query: query,
        body: apis,
        ...params,
      }),

    /**
     * No description
     *
     * @tags apis
     * @name ApisCreate
     * @request POST:/apis
     * @response `201` `unknown` Created
     */
    apisCreate: (
      apis: Apis,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/apis`,
        method: 'POST',
        query: query,
        body: apis,
        ...params,
      }),
  }
  assetBalances = {
    /**
     * No description
     *
     * @tags asset_balances
     * @name AssetBalancesList
     * @request GET:/asset_balances
     * @response `200` `(AssetBalances)[]` OK
     * @response `206` `unknown` Partial Content
     */
    assetBalancesList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format jsonb */
        balances?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetBalances[], any>({
        path: `/asset_balances`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  assetFairs = {
    /**
     * No description
     *
     * @tags asset_fairs
     * @name AssetFairsList
     * @request GET:/asset_fairs
     * @response `200` `(AssetFairs)[]` OK
     * @response `206` `unknown` Partial Content
     */
    assetFairsList: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format app_data.fair_source */
        fair_source?: string
        /** @format bigint */
        index_id?: string
        /** @format text */
        index_name?: string
        /** @format numeric */
        fair?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetFairs[], any>({
        path: `/asset_fairs`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  assetInfo = {
    /**
     * No description
     *
     * @tags asset_info
     * @name AssetInfoDelete
     * @request DELETE:/asset_info
     * @response `204` `unknown` No Content
     */
    assetInfoDelete: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format public.citext */
        ticker?: string
        /** @format text */
        name?: string
        /** @format text */
        short_summary?: string
        /** @format text */
        detailed_summary?: string
        /** @format bigint */
        selected_index_id?: string
        /** @format app_data.fair_source */
        fair_source?: string
        /** @format text[] */
        links?: string
        /** @format boolean */
        stablecoin?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/asset_info`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset_info
     * @name AssetInfoList
     * @request GET:/asset_info
     * @response `200` `(AssetInfo)[]` OK
     * @response `206` `unknown` Partial Content
     */
    assetInfoList: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format public.citext */
        ticker?: string
        /** @format text */
        name?: string
        /** @format text */
        short_summary?: string
        /** @format text */
        detailed_summary?: string
        /** @format bigint */
        selected_index_id?: string
        /** @format app_data.fair_source */
        fair_source?: string
        /** @format text[] */
        links?: string
        /** @format boolean */
        stablecoin?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetInfo[], any>({
        path: `/asset_info`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset_info
     * @name AssetInfoPartialUpdate
     * @request PATCH:/asset_info
     * @response `204` `unknown` No Content
     */
    assetInfoPartialUpdate: (
      asset_info: AssetInfo,
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format public.citext */
        ticker?: string
        /** @format text */
        name?: string
        /** @format text */
        short_summary?: string
        /** @format text */
        detailed_summary?: string
        /** @format bigint */
        selected_index_id?: string
        /** @format app_data.fair_source */
        fair_source?: string
        /** @format text[] */
        links?: string
        /** @format boolean */
        stablecoin?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/asset_info`,
        method: 'PATCH',
        query: query,
        body: asset_info,
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset_info
     * @name AssetInfoCreate
     * @request POST:/asset_info
     * @response `201` `unknown` Created
     */
    assetInfoCreate: (
      asset_info: AssetInfo,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/asset_info`,
        method: 'POST',
        query: query,
        body: asset_info,
        ...params,
      }),
  }
  assetStats = {
    /**
     * No description
     *
     * @tags asset_stats
     * @name AssetStatsList
     * @request GET:/asset_stats
     * @response `200` `(AssetStats)[]` OK
     * @response `206` `unknown` Partial Content
     */
    assetStatsList: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format double precision */
        beta?: string
        /** @format double precision */
        upside_beta?: string
        /** @format double precision */
        downside_beta?: string
        /** @format numeric */
        volume?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetStats[], any>({
        path: `/asset_stats`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  assetTags = {
    /**
     * No description
     *
     * @tags asset_tags
     * @name AssetTagsDelete
     * @request DELETE:/asset_tags
     * @response `204` `unknown` No Content
     */
    assetTagsDelete: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format bigint */
        tag_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/asset_tags`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset_tags
     * @name AssetTagsList
     * @request GET:/asset_tags
     * @response `200` `(AssetTags)[]` OK
     * @response `206` `unknown` Partial Content
     */
    assetTagsList: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format bigint */
        tag_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetTags[], any>({
        path: `/asset_tags`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset_tags
     * @name AssetTagsPartialUpdate
     * @request PATCH:/asset_tags
     * @response `204` `unknown` No Content
     */
    assetTagsPartialUpdate: (
      asset_tags: AssetTags,
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format bigint */
        tag_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/asset_tags`,
        method: 'PATCH',
        query: query,
        body: asset_tags,
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset_tags
     * @name AssetTagsCreate
     * @request POST:/asset_tags
     * @response `201` `unknown` Created
     */
    assetTagsCreate: (
      asset_tags: AssetTags,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/asset_tags`,
        method: 'POST',
        query: query,
        body: asset_tags,
        ...params,
      }),
  }
  assetVol1D = {
    /**
     * No description
     *
     * @tags asset_vol_1d
     * @name AssetVol1DList
     * @request GET:/asset_vol_1d
     * @response `200` `(AssetVol1D)[]` OK
     * @response `206` `unknown` Partial Content
     */
    assetVol1DList: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        volatility?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetVol1D[], any>({
        path: `/asset_vol_1d`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  assetVol1H = {
    /**
     * No description
     *
     * @tags asset_vol_1h
     * @name AssetVol1HList
     * @request GET:/asset_vol_1h
     * @response `200` `(AssetVol1H)[]` OK
     * @response `206` `unknown` Partial Content
     */
    assetVol1HList: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        volatility?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetVol1H[], any>({
        path: `/asset_vol_1h`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  assetVol1W = {
    /**
     * No description
     *
     * @tags asset_vol_1w
     * @name AssetVol1WList
     * @request GET:/asset_vol_1w
     * @response `200` `(AssetVol1W)[]` OK
     * @response `206` `unknown` Partial Content
     */
    assetVol1WList: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        volatility?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetVol1W[], any>({
        path: `/asset_vol_1w`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  assets = {
    /**
     * No description
     *
     * @tags assets
     * @name AssetsList
     * @request GET:/assets
     * @response `200` `(Assets)[]` OK
     * @response `206` `unknown` Partial Content
     */
    assetsList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format public.citext */
        ticker?: string
        /** @format app_data.asset_class */
        asset_class?: string
        /** @format boolean */
        stablecoin?: string
        /** @format app_data.quantity */
        contract_size?: string
        /** @format boolean */
        expired?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Assets[], any>({
        path: `/assets`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  balances = {
    /**
     * No description
     *
     * @tags balances
     * @name BalancesList
     * @request GET:/balances
     * @response `200` `(Balances)[]` OK
     * @response `206` `unknown` Partial Content
     */
    balancesList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        balance?: string
        /** @format numeric */
        free?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Balances[], any>({
        path: `/balances`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  borrowLimits = {
    /**
     * No description
     *
     * @tags borrow_limits
     * @name BorrowLimitsDelete
     * @request DELETE:/borrow_limits
     * @response `204` `unknown` No Content
     */
    borrowLimitsDelete: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        wallet_id?: string
        /** @format numeric */
        borrow_limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/borrow_limits`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags borrow_limits
     * @name BorrowLimitsList
     * @request GET:/borrow_limits
     * @response `200` `(BorrowLimits)[]` OK
     * @response `206` `unknown` Partial Content
     */
    borrowLimitsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        wallet_id?: string
        /** @format numeric */
        borrow_limit?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<BorrowLimits[], any>({
        path: `/borrow_limits`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags borrow_limits
     * @name BorrowLimitsPartialUpdate
     * @request PATCH:/borrow_limits
     * @response `204` `unknown` No Content
     */
    borrowLimitsPartialUpdate: (
      borrow_limits: BorrowLimits,
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        wallet_id?: string
        /** @format numeric */
        borrow_limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/borrow_limits`,
        method: 'PATCH',
        query: query,
        body: borrow_limits,
        ...params,
      }),

    /**
     * No description
     *
     * @tags borrow_limits
     * @name BorrowLimitsCreate
     * @request POST:/borrow_limits
     * @response `201` `unknown` Created
     */
    borrowLimitsCreate: (
      borrow_limits: BorrowLimits,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/borrow_limits`,
        method: 'POST',
        query: query,
        body: borrow_limits,
        ...params,
      }),
  }
  cancellationRequests = {
    /**
     * No description
     *
     * @tags cancellation_requests
     * @name CancellationRequestsDelete
     * @request DELETE:/cancellation_requests
     * @response `204` `unknown` No Content
     */
    cancellationRequestsDelete: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        id?: string
        /** @format bigint */
        order_id?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/cancellation_requests`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags cancellation_requests
     * @name CancellationRequestsList
     * @request GET:/cancellation_requests
     * @response `200` `(CancellationRequests)[]` OK
     * @response `206` `unknown` Partial Content
     */
    cancellationRequestsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        id?: string
        /** @format bigint */
        order_id?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<CancellationRequests[], any>({
        path: `/cancellation_requests`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cancellation_requests
     * @name CancellationRequestsPartialUpdate
     * @request PATCH:/cancellation_requests
     * @response `204` `unknown` No Content
     */
    cancellationRequestsPartialUpdate: (
      cancellation_requests: CancellationRequests,
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        id?: string
        /** @format bigint */
        order_id?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/cancellation_requests`,
        method: 'PATCH',
        query: query,
        body: cancellation_requests,
        ...params,
      }),

    /**
     * No description
     *
     * @tags cancellation_requests
     * @name CancellationRequestsCreate
     * @request POST:/cancellation_requests
     * @response `201` `unknown` Created
     */
    cancellationRequestsCreate: (
      cancellation_requests: CancellationRequests,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/cancellation_requests`,
        method: 'POST',
        query: query,
        body: cancellation_requests,
        ...params,
      }),
  }
  capitalConcentrationLimitUsage = {
    /**
     * No description
     *
     * @tags capital_concentration_limit_usage
     * @name CapitalConcentrationLimitUsageList
     * @request GET:/capital_concentration_limit_usage
     * @response `200` `(CapitalConcentrationLimitUsage)[]` OK
     * @response `206` `unknown` Partial Content
     */
    capitalConcentrationLimitUsageList: (
      query?: {
        /** @format bigint */
        exchange_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format numeric */
        concentration_limit?: string
        /** @format numeric */
        concentration_usage?: string
        /** @format double precision */
        concentration_proportion?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
        /** @format app_data.quantity */
        aum?: string
        /** @format app_data.quantity */
        excess?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<CapitalConcentrationLimitUsage[], any>({
        path: `/capital_concentration_limit_usage`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  capitalConcentrationLimits = {
    /**
     * No description
     *
     * @tags capital_concentration_limits
     * @name CapitalConcentrationLimitsDelete
     * @request DELETE:/capital_concentration_limits
     * @response `204` `unknown` No Content
     */
    capitalConcentrationLimitsDelete: (
      query?: {
        /** @format bigint */
        exchange_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format numeric */
        concentration_limit?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/capital_concentration_limits`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags capital_concentration_limits
     * @name CapitalConcentrationLimitsList
     * @request GET:/capital_concentration_limits
     * @response `200` `(CapitalConcentrationLimits)[]` OK
     * @response `206` `unknown` Partial Content
     */
    capitalConcentrationLimitsList: (
      query?: {
        /** @format bigint */
        exchange_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format numeric */
        concentration_limit?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<CapitalConcentrationLimits[], any>({
        path: `/capital_concentration_limits`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags capital_concentration_limits
     * @name CapitalConcentrationLimitsPartialUpdate
     * @request PATCH:/capital_concentration_limits
     * @response `204` `unknown` No Content
     */
    capitalConcentrationLimitsPartialUpdate: (
      capital_concentration_limits: CapitalConcentrationLimits,
      query?: {
        /** @format bigint */
        exchange_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format numeric */
        concentration_limit?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/capital_concentration_limits`,
        method: 'PATCH',
        query: query,
        body: capital_concentration_limits,
        ...params,
      }),

    /**
     * No description
     *
     * @tags capital_concentration_limits
     * @name CapitalConcentrationLimitsCreate
     * @request POST:/capital_concentration_limits
     * @response `201` `unknown` Created
     */
    capitalConcentrationLimitsCreate: (
      capital_concentration_limits: CapitalConcentrationLimits,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/capital_concentration_limits`,
        method: 'POST',
        query: query,
        body: capital_concentration_limits,
        ...params,
      }),
  }
  coinDeck = {
    /**
     * No description
     *
     * @tags coin_deck
     * @name CoinDeckList
     * @request GET:/coin_deck
     * @response `200` `(CoinDeck)[]` OK
     * @response `206` `unknown` Partial Content
     */
    coinDeckList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format public.citext */
        ticker?: string
        /** @format numeric */
        fair?: string
        /** @format interval */
        age?: string
        /** @format numeric */
        change_5m?: string
        /** @format numeric */
        change_30m?: string
        /** @format numeric */
        change_4h?: string
        /** @format numeric */
        change_24h?: string
        /** @format numeric */
        volume?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<CoinDeck[], any>({
        path: `/coin_deck`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  coinDeckAssetTags = {
    /**
     * No description
     *
     * @tags coin_deck_asset_tags
     * @name CoinDeckAssetTagsList
     * @request GET:/coin_deck_asset_tags
     * @response `200` `(CoinDeckAssetTags)[]` OK
     * @response `206` `unknown` Partial Content
     */
    coinDeckAssetTagsList: (
      query?: {
        /** @format public.citext */
        tag?: string
        /** @format bigint */
        id?: string
        /** @format public.citext */
        ticker?: string
        /** @format numeric */
        fair?: string
        /** @format interval */
        age?: string
        /** @format numeric */
        change_5m?: string
        /** @format numeric */
        change_30m?: string
        /** @format numeric */
        change_4h?: string
        /** @format numeric */
        change_24h?: string
        /** @format numeric */
        volume?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<CoinDeckAssetTags[], any>({
        path: `/coin_deck_asset_tags`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  coinDeckDerivatives = {
    /**
     * No description
     *
     * @tags coin_deck_derivatives
     * @name CoinDeckDerivativesList
     * @request GET:/coin_deck_derivatives
     * @response `200` `(CoinDeckDerivatives)[]` OK
     * @response `206` `unknown` Partial Content
     */
    coinDeckDerivativesList: (
      query?: {
        /** @format bigint */
        derivative_id?: string
        /** @format bigint */
        market_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format bigint */
        underlying_id?: string
        /** @format text */
        expiry_class?: string
        /** @format numeric */
        volume?: string
        /** @format numeric */
        swm?: string
        /** @format numeric */
        premium?: string
        /** @format double precision */
        days_to_expiry?: string
        /** @format numeric */
        fair?: string
        /** @format double precision */
        bps_per_day?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<CoinDeckDerivatives[], any>({
        path: `/coin_deck_derivatives`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  coinDeckOpenPredictions = {
    /**
     * No description
     *
     * @tags coin_deck_open_predictions
     * @name CoinDeckOpenPredictionsList
     * @request GET:/coin_deck_open_predictions
     * @response `200` `(CoinDeckOpenPredictions)[]` OK
     * @response `206` `unknown` Partial Content
     */
    coinDeckOpenPredictionsList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format public.citext */
        ticker?: string
        /** @format numeric */
        fair?: string
        /** @format interval */
        age?: string
        /** @format numeric */
        change_5m?: string
        /** @format numeric */
        change_30m?: string
        /** @format numeric */
        change_4h?: string
        /** @format numeric */
        change_24h?: string
        /** @format numeric */
        volume?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<CoinDeckOpenPredictions[], any>({
        path: `/coin_deck_open_predictions`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  coldBalances = {
    /**
     * No description
     *
     * @tags cold_balances
     * @name ColdBalancesDelete
     * @request DELETE:/cold_balances
     * @response `204` `unknown` No Content
     */
    coldBalancesDelete: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format text */
        name?: string
        /** @format bigint */
        asset_id?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
        /** @format numeric */
        balance?: string
        /** @format bigint */
        exchange_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/cold_balances`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags cold_balances
     * @name ColdBalancesList
     * @request GET:/cold_balances
     * @response `200` `(ColdBalances)[]` OK
     * @response `206` `unknown` Partial Content
     */
    coldBalancesList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format text */
        name?: string
        /** @format bigint */
        asset_id?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
        /** @format numeric */
        balance?: string
        /** @format bigint */
        exchange_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ColdBalances[], any>({
        path: `/cold_balances`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cold_balances
     * @name ColdBalancesPartialUpdate
     * @request PATCH:/cold_balances
     * @response `204` `unknown` No Content
     */
    coldBalancesPartialUpdate: (
      cold_balances: ColdBalances,
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format text */
        name?: string
        /** @format bigint */
        asset_id?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
        /** @format numeric */
        balance?: string
        /** @format bigint */
        exchange_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/cold_balances`,
        method: 'PATCH',
        query: query,
        body: cold_balances,
        ...params,
      }),

    /**
     * No description
     *
     * @tags cold_balances
     * @name ColdBalancesCreate
     * @request POST:/cold_balances
     * @response `201` `unknown` Created
     */
    coldBalancesCreate: (
      cold_balances: ColdBalances,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/cold_balances`,
        method: 'POST',
        query: query,
        body: cold_balances,
        ...params,
      }),
  }
  contractRiskLimits = {
    /**
     * No description
     *
     * @tags contract_risk_limits
     * @name ContractRiskLimitsDelete
     * @request DELETE:/contract_risk_limits
     * @response `204` `unknown` No Content
     */
    contractRiskLimitsDelete: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format app_data.quantity */
        max_notional?: string
        /** @format smallint */
        leverage_limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/contract_risk_limits`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract_risk_limits
     * @name ContractRiskLimitsList
     * @request GET:/contract_risk_limits
     * @response `200` `(ContractRiskLimits)[]` OK
     * @response `206` `unknown` Partial Content
     */
    contractRiskLimitsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format app_data.quantity */
        max_notional?: string
        /** @format smallint */
        leverage_limit?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ContractRiskLimits[], any>({
        path: `/contract_risk_limits`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract_risk_limits
     * @name ContractRiskLimitsPartialUpdate
     * @request PATCH:/contract_risk_limits
     * @response `204` `unknown` No Content
     */
    contractRiskLimitsPartialUpdate: (
      contract_risk_limits: ContractRiskLimits,
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format app_data.quantity */
        max_notional?: string
        /** @format smallint */
        leverage_limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/contract_risk_limits`,
        method: 'PATCH',
        query: query,
        body: contract_risk_limits,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract_risk_limits
     * @name ContractRiskLimitsCreate
     * @request POST:/contract_risk_limits
     * @response `201` `unknown` Created
     */
    contractRiskLimitsCreate: (
      contract_risk_limits: ContractRiskLimits,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/contract_risk_limits`,
        method: 'POST',
        query: query,
        body: contract_risk_limits,
        ...params,
      }),
  }
  credentialProviders = {
    /**
     * No description
     *
     * @tags credential_providers
     * @name CredentialProvidersDelete
     * @request DELETE:/credential_providers
     * @response `204` `unknown` No Content
     */
    credentialProvidersDelete: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format text */
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/credential_providers`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags credential_providers
     * @name CredentialProvidersList
     * @request GET:/credential_providers
     * @response `200` `(CredentialProviders)[]` OK
     * @response `206` `unknown` Partial Content
     */
    credentialProvidersList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format text */
        name?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<CredentialProviders[], any>({
        path: `/credential_providers`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags credential_providers
     * @name CredentialProvidersPartialUpdate
     * @request PATCH:/credential_providers
     * @response `204` `unknown` No Content
     */
    credentialProvidersPartialUpdate: (
      credential_providers: CredentialProviders,
      query?: {
        /** @format bigint */
        id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format text */
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/credential_providers`,
        method: 'PATCH',
        query: query,
        body: credential_providers,
        ...params,
      }),

    /**
     * No description
     *
     * @tags credential_providers
     * @name CredentialProvidersCreate
     * @request POST:/credential_providers
     * @response `201` `unknown` Created
     */
    credentialProvidersCreate: (
      credential_providers: CredentialProviders,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/credential_providers`,
        method: 'POST',
        query: query,
        body: credential_providers,
        ...params,
      }),
  }
  currentIndexFairs = {
    /**
     * No description
     *
     * @tags current_index_fairs
     * @name CurrentIndexFairsDelete
     * @request DELETE:/current_index_fairs
     * @response `204` `unknown` No Content
     */
    currentIndexFairsDelete: (
      query?: {
        /** @format bigint */
        index_id?: string
        /** @format numeric */
        fair?: string
        /** @format timestamp with time zone */
        ts?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/current_index_fairs`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags current_index_fairs
     * @name CurrentIndexFairsList
     * @request GET:/current_index_fairs
     * @response `200` `(CurrentIndexFairs)[]` OK
     * @response `206` `unknown` Partial Content
     */
    currentIndexFairsList: (
      query?: {
        /** @format bigint */
        index_id?: string
        /** @format numeric */
        fair?: string
        /** @format timestamp with time zone */
        ts?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<CurrentIndexFairs[], any>({
        path: `/current_index_fairs`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags current_index_fairs
     * @name CurrentIndexFairsPartialUpdate
     * @request PATCH:/current_index_fairs
     * @response `204` `unknown` No Content
     */
    currentIndexFairsPartialUpdate: (
      current_index_fairs: CurrentIndexFairs,
      query?: {
        /** @format bigint */
        index_id?: string
        /** @format numeric */
        fair?: string
        /** @format timestamp with time zone */
        ts?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/current_index_fairs`,
        method: 'PATCH',
        query: query,
        body: current_index_fairs,
        ...params,
      }),

    /**
     * No description
     *
     * @tags current_index_fairs
     * @name CurrentIndexFairsCreate
     * @request POST:/current_index_fairs
     * @response `201` `unknown` Created
     */
    currentIndexFairsCreate: (
      current_index_fairs: CurrentIndexFairs,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/current_index_fairs`,
        method: 'POST',
        query: query,
        body: current_index_fairs,
        ...params,
      }),
  }
  deltaLimitUsage = {
    /**
     * No description
     *
     * @tags delta_limit_usage
     * @name DeltaLimitUsageList
     * @request GET:/delta_limit_usage
     * @response `200` `(DeltaLimitUsage)[]` OK
     * @response `206` `unknown` Partial Content
     */
    deltaLimitUsageList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format public.citext */
        asset?: string
        /** @format text */
        delta_limit_asset_id?: string
        /** @format app_data.quantity */
        target_delta?: string
        /** @format numeric */
        delta?: string
        /** @format numeric */
        delta_to_target?: string
        /** @format numeric */
        delta_btc?: string
        /** @format numeric */
        delta_to_target_btc?: string
        /** @format numeric */
        delta_limit_lower?: string
        /** @format numeric */
        delta_limit_midpoint?: string
        /** @format numeric */
        delta_limit_upper?: string
        /** @format text */
        note?: string
        /** @format numeric */
        aum_btc?: string
        /** @format numeric */
        delta_ratio?: string
        /** @format numeric */
        delta_to_target_ratio?: string
        /** @format numeric */
        delta_limit_proportion?: string
        /** @format numeric */
        delta_limit_aum_proportion?: string
        /** @format numeric */
        delta_from_closest_limit?: string
        /** @format numeric */
        delta_from_limit_midpoint?: string
        /** @format boolean */
        breach?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
        /** @format text */
        updated_by_name?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<DeltaLimitUsage[], any>({
        path: `/delta_limit_usage`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  deltaLimits = {
    /**
     * No description
     *
     * @tags delta_limits
     * @name DeltaLimitsDelete
     * @request DELETE:/delta_limits
     * @response `204` `unknown` No Content
     */
    deltaLimitsDelete: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        delta_limit_lower?: string
        /** @format numeric */
        delta_limit_upper?: string
        /** @format text */
        note?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/delta_limits`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags delta_limits
     * @name DeltaLimitsList
     * @request GET:/delta_limits
     * @response `200` `(DeltaLimits)[]` OK
     * @response `206` `unknown` Partial Content
     */
    deltaLimitsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        delta_limit_lower?: string
        /** @format numeric */
        delta_limit_upper?: string
        /** @format text */
        note?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<DeltaLimits[], any>({
        path: `/delta_limits`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags delta_limits
     * @name DeltaLimitsPartialUpdate
     * @request PATCH:/delta_limits
     * @response `204` `unknown` No Content
     */
    deltaLimitsPartialUpdate: (
      delta_limits: DeltaLimits,
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        delta_limit_lower?: string
        /** @format numeric */
        delta_limit_upper?: string
        /** @format text */
        note?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/delta_limits`,
        method: 'PATCH',
        query: query,
        body: delta_limits,
        ...params,
      }),

    /**
     * No description
     *
     * @tags delta_limits
     * @name DeltaLimitsCreate
     * @request POST:/delta_limits
     * @response `201` `unknown` Created
     */
    deltaLimitsCreate: (
      delta_limits: DeltaLimits,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/delta_limits`,
        method: 'POST',
        query: query,
        body: delta_limits,
        ...params,
      }),
  }
  deltas = {
    /**
     * No description
     *
     * @tags deltas
     * @name DeltasList
     * @request GET:/deltas
     * @response `200` `(Deltas)[]` OK
     * @response `206` `unknown` Partial Content
     */
    deltasList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        delta?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Deltas[], any>({
        path: `/deltas`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  derivativeMarketHoldings = {
    /**
     * No description
     *
     * @tags derivative_market_holdings
     * @name DerivativeMarketHoldingsList
     * @request GET:/derivative_market_holdings
     * @response `200` `(DerivativeMarketHoldings)[]` OK
     * @response `206` `unknown` Partial Content
     */
    derivativeMarketHoldingsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        market_id?: string
        /** @format numeric */
        delta?: string
        /** @format numeric */
        position?: string
        /** @format numeric */
        total_margin?: string
        /** @format numeric */
        free_margin?: string
        /** @format bigint */
        margin_asset_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<DerivativeMarketHoldings[], any>({
        path: `/derivative_market_holdings`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  derivatives = {
    /**
     * No description
     *
     * @tags derivatives
     * @name DerivativesList
     * @request GET:/derivatives
     * @response `200` `(Derivatives)[]` OK
     * @response `206` `unknown` Partial Content
     */
    derivativesList: (
      query?: {
        /** @format bigint */
        derivative_id?: string
        /** @format public.citext */
        ticker?: string
        /** @format app_data.asset_class */
        asset_class?: string
        /** @format bigint */
        exchange_id?: string
        /** @format bigint */
        underlying_id?: string
        /** @format bigint */
        currency_id?: string
        /** @format text */
        expiry_class?: string
        /** @format timestamp with time zone */
        expiry?: string
        /** @format app_data.quantity */
        contract_size?: string
        /** @format app_data.quantity_t */
        quanto_multiplier?: string
        /** @format bigint */
        market_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Derivatives[], any>({
        path: `/derivatives`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  derivativesFixedExpiry = {
    /**
     * No description
     *
     * @tags derivatives_fixed_expiry
     * @name DerivativesFixedExpiryList
     * @request GET:/derivatives_fixed_expiry
     * @response `200` `(DerivativesFixedExpiry)[]` OK
     * @response `206` `unknown` Partial Content
     */
    derivativesFixedExpiryList: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format bigint */
        market_id?: string
        /** @format bigint */
        hedge_market_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format bigint */
        underlying_id?: string
        /** @format bigint */
        currency_id?: string
        /** @format numeric */
        volume?: string
        /** @format numeric */
        swm?: string
        /** @format numeric */
        premium_bps?: string
        /** @format numeric */
        premium_change_1h_bps?: string
        /** @format numeric */
        premium_change_1d_bps?: string
        /** @format numeric */
        premium_change_3d_bps?: string
        /** @format numeric */
        premium_change_7d_bps?: string
        /** @format timestamp with time zone */
        expiry?: string
        /** @format numeric */
        days_to_expiry?: string
        /** @format numeric */
        fair?: string
        /** @format text */
        derivative_class?: string
        /** @format text */
        expiry_class?: string
        /** @format boolean */
        premium_uses_single_hedge_market?: string
        /** @format numeric */
        bps_per_day?: string
        /** @format bigint */
        tenant_id?: string
        /** @format numeric */
        position?: string
        /** @format numeric */
        position_usd?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<DerivativesFixedExpiry[], any>({
        path: `/derivatives_fixed_expiry`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  exchangeBalances = {
    /**
     * No description
     *
     * @tags exchange_balances
     * @name ExchangeBalancesList
     * @request GET:/exchange_balances
     * @response `200` `(ExchangeBalances)[]` OK
     * @response `206` `unknown` Partial Content
     */
    exchangeBalancesList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format text */
        exchange?: string
        /** @format numeric */
        balance?: string
        /** @format bigint */
        exchange_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ExchangeBalances[], any>({
        path: `/exchange_balances`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  exchanges = {
    /**
     * No description
     *
     * @tags exchanges
     * @name ExchangesDelete
     * @request DELETE:/exchanges
     * @response `204` `unknown` No Content
     */
    exchangesDelete: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/exchanges`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags exchanges
     * @name ExchangesList
     * @request GET:/exchanges
     * @response `200` `(Exchanges)[]` OK
     * @response `206` `unknown` Partial Content
     */
    exchangesList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Exchanges[], any>({
        path: `/exchanges`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags exchanges
     * @name ExchangesPartialUpdate
     * @request PATCH:/exchanges
     * @response `204` `unknown` No Content
     */
    exchangesPartialUpdate: (
      exchanges: Exchanges,
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/exchanges`,
        method: 'PATCH',
        query: query,
        body: exchanges,
        ...params,
      }),

    /**
     * No description
     *
     * @tags exchanges
     * @name ExchangesCreate
     * @request POST:/exchanges
     * @response `201` `unknown` Created
     */
    exchangesCreate: (
      exchanges: Exchanges,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/exchanges`,
        method: 'POST',
        query: query,
        body: exchanges,
        ...params,
      }),
  }
  expectedNavs = {
    /**
     * No description
     *
     * @tags expected_navs
     * @name ExpectedNavsList
     * @request GET:/expected_navs
     * @response `200` `(ExpectedNavs)[]` OK
     * @response `206` `unknown` Partial Content
     */
    expectedNavsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format app_data.quantity */
        expected_nav?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ExpectedNavs[], any>({
        path: `/expected_navs`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  fairs = {
    /**
     * No description
     *
     * @tags fairs
     * @name FairsList
     * @request GET:/fairs
     * @response `200` `(Fairs)[]` OK
     * @response `206` `unknown` Partial Content
     */
    fairsList: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        fair?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Fairs[], any>({
        path: `/fairs`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  fills = {
    /**
     * No description
     *
     * @tags fills
     * @name FillsList
     * @request GET:/fills
     * @response `200` `(Fills)[]` OK
     * @response `206` `unknown` Partial Content
     */
    fillsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        id?: string
        /** @format bigint */
        market_id?: string
        /** @format numeric */
        price?: string
        /** @format numeric */
        quantity?: string
        /** @format app_data.direction */
        direction?: string
        /** @format bigint */
        underlying_id?: string
        /** @format timestamp with time zone */
        filled_at?: string
        /** @format text */
        strategy?: string
        /** @format text */
        creator?: string
        /** @format numeric */
        fee?: string
        /** @format bigint */
        fee_currency_id?: string
        /** @format bigint */
        order_id?: string
        /** @format app_data.trade_source */
        source?: string
        /** @format interval */
        scrape_latency?: string
        /** @format numeric */
        fair?: string
        /** @format numeric */
        fair_delta_bps?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Fills[], any>({
        path: `/fills`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  fireblocksAccountRules = {
    /**
     * No description
     *
     * @tags fireblocks_account_rules
     * @name FireblocksAccountRulesDelete
     * @request DELETE:/fireblocks_account_rules
     * @response `204` `unknown` No Content
     */
    fireblocksAccountRulesDelete: (
      query?: {
        /** @format bigint */
        source_account_id?: string
        /** @format bigint */
        dest_account_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format app_data.allow_deny */
        permission?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/fireblocks_account_rules`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags fireblocks_account_rules
     * @name FireblocksAccountRulesList
     * @request GET:/fireblocks_account_rules
     * @response `200` `(FireblocksAccountRules)[]` OK
     * @response `206` `unknown` Partial Content
     */
    fireblocksAccountRulesList: (
      query?: {
        /** @format bigint */
        source_account_id?: string
        /** @format bigint */
        dest_account_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format app_data.allow_deny */
        permission?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<FireblocksAccountRules[], any>({
        path: `/fireblocks_account_rules`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags fireblocks_account_rules
     * @name FireblocksAccountRulesPartialUpdate
     * @request PATCH:/fireblocks_account_rules
     * @response `204` `unknown` No Content
     */
    fireblocksAccountRulesPartialUpdate: (
      fireblocks_account_rules: FireblocksAccountRules,
      query?: {
        /** @format bigint */
        source_account_id?: string
        /** @format bigint */
        dest_account_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format app_data.allow_deny */
        permission?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/fireblocks_account_rules`,
        method: 'PATCH',
        query: query,
        body: fireblocks_account_rules,
        ...params,
      }),

    /**
     * No description
     *
     * @tags fireblocks_account_rules
     * @name FireblocksAccountRulesCreate
     * @request POST:/fireblocks_account_rules
     * @response `201` `unknown` Created
     */
    fireblocksAccountRulesCreate: (
      fireblocks_account_rules: FireblocksAccountRules,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/fireblocks_account_rules`,
        method: 'POST',
        query: query,
        body: fireblocks_account_rules,
        ...params,
      }),
  }
  fireblocksAccounts = {
    /**
     * No description
     *
     * @tags fireblocks_accounts
     * @name FireblocksAccountsList
     * @request GET:/fireblocks_accounts
     * @response `200` `(FireblocksAccounts)[]` OK
     * @response `206` `unknown` Partial Content
     */
    fireblocksAccountsList: (
      query?: {
        /** @format bigint */
        account_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format text */
        exchange?: string
        /** @format fireblocks.account_type */
        account_type?: string
        /** @format text */
        fireblocks_id?: string
        /** @format text */
        account_name?: string
        /** @format boolean */
        is_subaccount?: string
        /** @format jsonb */
        asset_balances?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<FireblocksAccounts[], any>({
        path: `/fireblocks_accounts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  fireblocksAllowedDestAccounts = {
    /**
     * No description
     *
     * @tags fireblocks_allowed_dest_accounts
     * @name FireblocksAllowedDestAccountsList
     * @request GET:/fireblocks_allowed_dest_accounts
     * @response `200` `(FireblocksAllowedDestAccounts)[]` OK
     * @response `206` `unknown` Partial Content
     */
    fireblocksAllowedDestAccountsList: (
      query?: {
        /** @format bigint */
        source_account_id?: string
        /** @format bigint */
        dest_account_id?: string
        /** @format bigint */
        tenant_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<FireblocksAllowedDestAccounts[], any>({
        path: `/fireblocks_allowed_dest_accounts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  fireblocksBalances = {
    /**
     * No description
     *
     * @tags fireblocks_balances
     * @name FireblocksBalancesList
     * @request GET:/fireblocks_balances
     * @response `200` `(FireblocksBalances)[]` OK
     * @response `206` `unknown` Partial Content
     */
    fireblocksBalancesList: (
      query?: {
        /** @format bigint */
        account_id?: string
        /** @format text */
        account_name?: string
        /** @format fireblocks.account_type */
        account_type?: string
        /** @format bigint */
        exchange_id?: string
        /** @format text */
        exchange?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format public.citext */
        ticker?: string
        /** @format numeric */
        balance?: string
        /** @format numeric */
        free?: string
        /** @format text */
        blockchain?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<FireblocksBalances[], any>({
        path: `/fireblocks_balances`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  fireblocksKnownAccountAssets = {
    /**
     * No description
     *
     * @tags fireblocks_known_account_assets
     * @name FireblocksKnownAccountAssetsList
     * @request GET:/fireblocks_known_account_assets
     * @response `200` `(FireblocksKnownAccountAssets)[]` OK
     * @response `206` `unknown` Partial Content
     */
    fireblocksKnownAccountAssetsList: (
      query?: {
        /** @format bigint */
        account_id?: string
        /** @format bigint[] */
        asset_ids?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<FireblocksKnownAccountAssets[], any>({
        path: `/fireblocks_known_account_assets`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  fireblocksSupportedAssets = {
    /**
     * No description
     *
     * @tags fireblocks_supported_assets
     * @name FireblocksSupportedAssetsDelete
     * @request DELETE:/fireblocks_supported_assets
     * @response `204` `unknown` No Content
     */
    fireblocksSupportedAssetsDelete: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format text */
        name?: string
        /** @format text */
        type?: string
        /** @format text */
        contract_address?: string
        /** @format text */
        native_asset?: string
        /** @format bigint */
        decimals?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/fireblocks_supported_assets`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags fireblocks_supported_assets
     * @name FireblocksSupportedAssetsList
     * @request GET:/fireblocks_supported_assets
     * @response `200` `(FireblocksSupportedAssets)[]` OK
     * @response `206` `unknown` Partial Content
     */
    fireblocksSupportedAssetsList: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format text */
        name?: string
        /** @format text */
        type?: string
        /** @format text */
        contract_address?: string
        /** @format text */
        native_asset?: string
        /** @format bigint */
        decimals?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<FireblocksSupportedAssets[], any>({
        path: `/fireblocks_supported_assets`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags fireblocks_supported_assets
     * @name FireblocksSupportedAssetsPartialUpdate
     * @request PATCH:/fireblocks_supported_assets
     * @response `204` `unknown` No Content
     */
    fireblocksSupportedAssetsPartialUpdate: (
      fireblocks_supported_assets: FireblocksSupportedAssets,
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format text */
        name?: string
        /** @format text */
        type?: string
        /** @format text */
        contract_address?: string
        /** @format text */
        native_asset?: string
        /** @format bigint */
        decimals?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/fireblocks_supported_assets`,
        method: 'PATCH',
        query: query,
        body: fireblocks_supported_assets,
        ...params,
      }),

    /**
     * No description
     *
     * @tags fireblocks_supported_assets
     * @name FireblocksSupportedAssetsCreate
     * @request POST:/fireblocks_supported_assets
     * @response `201` `unknown` Created
     */
    fireblocksSupportedAssetsCreate: (
      fireblocks_supported_assets: FireblocksSupportedAssets,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/fireblocks_supported_assets`,
        method: 'POST',
        query: query,
        body: fireblocks_supported_assets,
        ...params,
      }),
  }
  fireblocksTransactionRequests = {
    /**
     * No description
     *
     * @tags fireblocks_transaction_requests
     * @name FireblocksTransactionRequestsDelete
     * @request DELETE:/fireblocks_transaction_requests
     * @response `204` `unknown` No Content
     */
    fireblocksTransactionRequestsDelete: (
      query?: {
        /** @format bigint */
        transaction_request_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        source_account_id?: string
        /** @format bigint */
        dest_account_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        amount?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
        /** @format text */
        note?: string
        /** @format numeric */
        gas_price?: string
        /** @format numeric */
        fee?: string
        /** @format fireblocks.fee_level */
        fee_level?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/fireblocks_transaction_requests`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags fireblocks_transaction_requests
     * @name FireblocksTransactionRequestsList
     * @request GET:/fireblocks_transaction_requests
     * @response `200` `(FireblocksTransactionRequests)[]` OK
     * @response `206` `unknown` Partial Content
     */
    fireblocksTransactionRequestsList: (
      query?: {
        /** @format bigint */
        transaction_request_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        source_account_id?: string
        /** @format bigint */
        dest_account_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        amount?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
        /** @format text */
        note?: string
        /** @format numeric */
        gas_price?: string
        /** @format numeric */
        fee?: string
        /** @format fireblocks.fee_level */
        fee_level?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<FireblocksTransactionRequests[], any>({
        path: `/fireblocks_transaction_requests`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags fireblocks_transaction_requests
     * @name FireblocksTransactionRequestsPartialUpdate
     * @request PATCH:/fireblocks_transaction_requests
     * @response `204` `unknown` No Content
     */
    fireblocksTransactionRequestsPartialUpdate: (
      fireblocks_transaction_requests: FireblocksTransactionRequests,
      query?: {
        /** @format bigint */
        transaction_request_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        source_account_id?: string
        /** @format bigint */
        dest_account_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        amount?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
        /** @format text */
        note?: string
        /** @format numeric */
        gas_price?: string
        /** @format numeric */
        fee?: string
        /** @format fireblocks.fee_level */
        fee_level?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/fireblocks_transaction_requests`,
        method: 'PATCH',
        query: query,
        body: fireblocks_transaction_requests,
        ...params,
      }),

    /**
     * No description
     *
     * @tags fireblocks_transaction_requests
     * @name FireblocksTransactionRequestsCreate
     * @request POST:/fireblocks_transaction_requests
     * @response `201` `unknown` Created
     */
    fireblocksTransactionRequestsCreate: (
      fireblocks_transaction_requests: FireblocksTransactionRequests,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/fireblocks_transaction_requests`,
        method: 'POST',
        query: query,
        body: fireblocks_transaction_requests,
        ...params,
      }),
  }
  fireblocksTransactions = {
    /**
     * No description
     *
     * @tags fireblocks_transactions
     * @name FireblocksTransactionsList
     * @request GET:/fireblocks_transactions
     * @response `200` `(FireblocksTransactions)[]` OK
     * @response `206` `unknown` Partial Content
     */
    fireblocksTransactionsList: (
      query?: {
        /** @format bigint */
        transaction_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format text */
        fireblocks_id?: string
        /** @format public.citext */
        asset_ticker?: string
        /** @format bigint */
        asset_id?: string
        /** @format text */
        blockchain?: string
        /** @format bigint */
        source_account_id?: string
        /** @format text */
        source_account?: string
        /** @format text */
        source_exchange?: string
        /** @format bigint */
        dest_account_id?: string
        /** @format text */
        dest_account?: string
        /** @format text */
        dest_exchange?: string
        /** @format numeric */
        requested_amount?: string
        /** @format numeric */
        net_amount?: string
        /** @format numeric */
        network_fee?: string
        /** @format numeric */
        service_fee?: string
        /** @format bigint */
        fee_currency_id?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format timestamp with time zone */
        scraped_at?: string
        /** @format text */
        status?: string
        /** @format text */
        status_description?: string
        /** @format boolean */
        status_is_closed?: string
        /** @format text */
        sub_status?: string
        /** @format text */
        note?: string
        /** @format text */
        created_by?: string
        /** @format text */
        rejected_by?: string
        /** @format text */
        transaction_hash?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<FireblocksTransactions[], any>({
        path: `/fireblocks_transactions`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  fundingRates = {
    /**
     * No description
     *
     * @tags funding_rates
     * @name FundingRatesList
     * @request GET:/funding_rates
     * @response `200` `(FundingRates)[]` OK
     * @response `206` `unknown` Partial Content
     */
    fundingRatesList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        market_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format bigint */
        underlying_id?: string
        /** @format public.citext */
        symbol?: string
        /** @format bigint */
        hedge_market_id?: string
        /** @format numeric */
        premium_bps?: string
        /** @format numeric */
        position?: string
        /** @format numeric */
        position_usd?: string
        /** @format numeric */
        volume?: string
        /** @format numeric */
        total_1d_bps?: string
        /** @format numeric */
        total_3d_bps?: string
        /** @format numeric */
        total_7d_bps?: string
        /** @format numeric */
        swap_bid_bps?: string
        /** @format numeric */
        swap_ask_bps?: string
        /** @format numeric */
        spot_bid_bps?: string
        /** @format numeric */
        spot_ask_bps?: string
        /** @format text */
        derivative_class?: string
        /** @format bigint */
        num_component_rates_1d?: string
        /** @format bigint */
        num_component_rates_3d?: string
        /** @format bigint */
        num_component_rates_7d?: string
        /** @format text */
        exchange?: string
        /** @format numeric */
        next_funding_rate_bps?: string
        /** @format timestamp with time zone */
        next_funding_rate_ts?: string
        /** @format numeric */
        indicative_funding_rate_bps?: string
        /** @format timestamp with time zone */
        indicative_funding_rate_ts?: string
        /** @format timestamp with time zone */
        indicative_funding_rate_funding_event_ts?: string
        /** @format bigint */
        margin_limit_tenant_id?: string
        /** @format numeric */
        reference_margin_limit?: string
        /** @format numeric */
        roc_multiplier?: string
        /** @format numeric */
        roc_1d?: string
        /** @format numeric */
        roc_3d?: string
        /** @format numeric */
        roc_7d?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<FundingRates[], any>({
        path: `/funding_rates`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  hedgeRequests = {
    /**
     * No description
     *
     * @tags hedge_requests
     * @name HedgeRequestsDelete
     * @request DELETE:/hedge_requests
     * @response `204` `unknown` No Content
     */
    hedgeRequestsDelete: (
      query?: {
        /** @format bigint */
        trade_request_id?: string
        /** @format bigint */
        market_id?: string
        /** @format app_data.margin_mode */
        margin_mode?: string
        /** @format integer */
        delay?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/hedge_requests`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags hedge_requests
     * @name HedgeRequestsList
     * @request GET:/hedge_requests
     * @response `200` `(HedgeRequests)[]` OK
     * @response `206` `unknown` Partial Content
     */
    hedgeRequestsList: (
      query?: {
        /** @format bigint */
        trade_request_id?: string
        /** @format bigint */
        market_id?: string
        /** @format app_data.margin_mode */
        margin_mode?: string
        /** @format integer */
        delay?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<HedgeRequests[], any>({
        path: `/hedge_requests`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags hedge_requests
     * @name HedgeRequestsPartialUpdate
     * @request PATCH:/hedge_requests
     * @response `204` `unknown` No Content
     */
    hedgeRequestsPartialUpdate: (
      hedge_requests: HedgeRequests,
      query?: {
        /** @format bigint */
        trade_request_id?: string
        /** @format bigint */
        market_id?: string
        /** @format app_data.margin_mode */
        margin_mode?: string
        /** @format integer */
        delay?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/hedge_requests`,
        method: 'PATCH',
        query: query,
        body: hedge_requests,
        ...params,
      }),

    /**
     * No description
     *
     * @tags hedge_requests
     * @name HedgeRequestsCreate
     * @request POST:/hedge_requests
     * @response `201` `unknown` Created
     */
    hedgeRequestsCreate: (
      hedge_requests: HedgeRequests,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/hedge_requests`,
        method: 'POST',
        query: query,
        body: hedge_requests,
        ...params,
      }),
  }
  hedges = {
    /**
     * No description
     *
     * @tags hedges
     * @name HedgesList
     * @request GET:/hedges
     * @response `200` `(Hedges)[]` OK
     * @response `206` `unknown` Partial Content
     */
    hedgesList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        delta?: string
        /** @format numeric */
        btc_delta?: string
        /** @format double precision */
        beta?: string
        /** @format double precision */
        hedge?: string
        /** @format double precision */
        upside_beta?: string
        /** @format double precision */
        upside_hedge?: string
        /** @format double precision */
        downside_beta?: string
        /** @format double precision */
        downside_hedge?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Hedges[], any>({
        path: `/hedges`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  hotWallets = {
    /**
     * No description
     *
     * @tags hot_wallets
     * @name HotWalletsDelete
     * @request DELETE:/hot_wallets
     * @response `204` `unknown` No Content
     */
    hotWalletsDelete: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format public.citext */
        name?: string
        /** @format app_data.wallet_type */
        wallet_type?: string
        /** @format boolean */
        disabled?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/hot_wallets`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags hot_wallets
     * @name HotWalletsList
     * @request GET:/hot_wallets
     * @response `200` `(HotWallets)[]` OK
     * @response `206` `unknown` Partial Content
     */
    hotWalletsList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format public.citext */
        name?: string
        /** @format app_data.wallet_type */
        wallet_type?: string
        /** @format boolean */
        disabled?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<HotWallets[], any>({
        path: `/hot_wallets`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags hot_wallets
     * @name HotWalletsPartialUpdate
     * @request PATCH:/hot_wallets
     * @response `204` `unknown` No Content
     */
    hotWalletsPartialUpdate: (
      hot_wallets: HotWallets,
      query?: {
        /** @format bigint */
        id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format public.citext */
        name?: string
        /** @format app_data.wallet_type */
        wallet_type?: string
        /** @format boolean */
        disabled?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/hot_wallets`,
        method: 'PATCH',
        query: query,
        body: hot_wallets,
        ...params,
      }),

    /**
     * No description
     *
     * @tags hot_wallets
     * @name HotWalletsCreate
     * @request POST:/hot_wallets
     * @response `201` `unknown` Created
     */
    hotWalletsCreate: (
      hot_wallets: HotWallets,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/hot_wallets`,
        method: 'POST',
        query: query,
        body: hot_wallets,
        ...params,
      }),
  }
  indices = {
    /**
     * No description
     *
     * @tags indices
     * @name IndicesDelete
     * @request DELETE:/indices
     * @response `204` `unknown` No Content
     */
    indicesDelete: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
        /** @format text */
        definition?: string
        /** @format bigint */
        asset_id?: string
        /** @format boolean */
        active?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        created_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/indices`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags indices
     * @name IndicesList
     * @request GET:/indices
     * @response `200` `(Indices)[]` OK
     * @response `206` `unknown` Partial Content
     */
    indicesList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
        /** @format text */
        definition?: string
        /** @format bigint */
        asset_id?: string
        /** @format boolean */
        active?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        created_by_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Indices[], any>({
        path: `/indices`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags indices
     * @name IndicesPartialUpdate
     * @request PATCH:/indices
     * @response `204` `unknown` No Content
     */
    indicesPartialUpdate: (
      indices: Indices,
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
        /** @format text */
        definition?: string
        /** @format bigint */
        asset_id?: string
        /** @format boolean */
        active?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        created_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/indices`,
        method: 'PATCH',
        query: query,
        body: indices,
        ...params,
      }),

    /**
     * No description
     *
     * @tags indices
     * @name IndicesCreate
     * @request POST:/indices
     * @response `201` `unknown` Created
     */
    indicesCreate: (
      indices: Indices,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/indices`,
        method: 'POST',
        query: query,
        body: indices,
        ...params,
      }),
  }
  indicesMonitoring = {
    /**
     * No description
     *
     * @tags indices_monitoring
     * @name IndicesMonitoringList
     * @request GET:/indices_monitoring
     * @response `200` `(IndicesMonitoring)[]` OK
     * @response `206` `unknown` Partial Content
     */
    indicesMonitoringList: (
      query?: {
        /** @format text */
        index_name?: string
        /** @format bigint */
        index_id?: string
        /** @format numeric */
        fair?: string
        /** @format numrange */
        alert_outside_of_range?: string
        /** @format text */
        target?: string
        /** @format interval */
        mute_for?: string
        /** @format boolean */
        is_alert?: string
        /** @format text */
        definition?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<IndicesMonitoring[], any>({
        path: `/indices_monitoring`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  indicesMonitoringThresholds = {
    /**
     * No description
     *
     * @tags indices_monitoring_thresholds
     * @name IndicesMonitoringThresholdsDelete
     * @request DELETE:/indices_monitoring_thresholds
     * @response `204` `unknown` No Content
     */
    indicesMonitoringThresholdsDelete: (
      query?: {
        /** @format bigint */
        index_id?: string
        /** @format numrange */
        alert_outside_of_range?: string
        /** @format text */
        target?: string
        /** @format interval */
        mute_for?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/indices_monitoring_thresholds`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags indices_monitoring_thresholds
     * @name IndicesMonitoringThresholdsList
     * @request GET:/indices_monitoring_thresholds
     * @response `200` `(IndicesMonitoringThresholds)[]` OK
     * @response `206` `unknown` Partial Content
     */
    indicesMonitoringThresholdsList: (
      query?: {
        /** @format bigint */
        index_id?: string
        /** @format numrange */
        alert_outside_of_range?: string
        /** @format text */
        target?: string
        /** @format interval */
        mute_for?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<IndicesMonitoringThresholds[], any>({
        path: `/indices_monitoring_thresholds`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags indices_monitoring_thresholds
     * @name IndicesMonitoringThresholdsPartialUpdate
     * @request PATCH:/indices_monitoring_thresholds
     * @response `204` `unknown` No Content
     */
    indicesMonitoringThresholdsPartialUpdate: (
      indices_monitoring_thresholds: IndicesMonitoringThresholds,
      query?: {
        /** @format bigint */
        index_id?: string
        /** @format numrange */
        alert_outside_of_range?: string
        /** @format text */
        target?: string
        /** @format interval */
        mute_for?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/indices_monitoring_thresholds`,
        method: 'PATCH',
        query: query,
        body: indices_monitoring_thresholds,
        ...params,
      }),

    /**
     * No description
     *
     * @tags indices_monitoring_thresholds
     * @name IndicesMonitoringThresholdsCreate
     * @request POST:/indices_monitoring_thresholds
     * @response `201` `unknown` Created
     */
    indicesMonitoringThresholdsCreate: (
      indices_monitoring_thresholds: IndicesMonitoringThresholds,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/indices_monitoring_thresholds`,
        method: 'POST',
        query: query,
        body: indices_monitoring_thresholds,
        ...params,
      }),
  }
  leverageLimitChanges = {
    /**
     * No description
     *
     * @tags leverage_limit_changes
     * @name LeverageLimitChangesDelete
     * @request DELETE:/leverage_limit_changes
     * @response `204` `unknown` No Content
     */
    leverageLimitChangesDelete: (
      query?: {
        /** @format integer */
        leverage_limit_change_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format smallint */
        leverage_limit?: string
        /** @format bigint */
        created_by_id?: string
        /** @format timestamp with time zone */
        created_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/leverage_limit_changes`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags leverage_limit_changes
     * @name LeverageLimitChangesList
     * @request GET:/leverage_limit_changes
     * @response `200` `(LeverageLimitChanges)[]` OK
     * @response `206` `unknown` Partial Content
     */
    leverageLimitChangesList: (
      query?: {
        /** @format integer */
        leverage_limit_change_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format smallint */
        leverage_limit?: string
        /** @format bigint */
        created_by_id?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<LeverageLimitChanges[], any>({
        path: `/leverage_limit_changes`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags leverage_limit_changes
     * @name LeverageLimitChangesPartialUpdate
     * @request PATCH:/leverage_limit_changes
     * @response `204` `unknown` No Content
     */
    leverageLimitChangesPartialUpdate: (
      leverage_limit_changes: LeverageLimitChanges,
      query?: {
        /** @format integer */
        leverage_limit_change_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format smallint */
        leverage_limit?: string
        /** @format bigint */
        created_by_id?: string
        /** @format timestamp with time zone */
        created_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/leverage_limit_changes`,
        method: 'PATCH',
        query: query,
        body: leverage_limit_changes,
        ...params,
      }),

    /**
     * No description
     *
     * @tags leverage_limit_changes
     * @name LeverageLimitChangesCreate
     * @request POST:/leverage_limit_changes
     * @response `201` `unknown` Created
     */
    leverageLimitChangesCreate: (
      leverage_limit_changes: LeverageLimitChanges,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/leverage_limit_changes`,
        method: 'POST',
        query: query,
        body: leverage_limit_changes,
        ...params,
      }),
  }
  marginAccounts = {
    /**
     * No description
     *
     * @tags margin_accounts
     * @name MarginAccountsDelete
     * @request DELETE:/margin_accounts
     * @response `204` `unknown` No Content
     */
    marginAccountsDelete: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format app_data.quantity */
        total_collateral?: string
        /** @format timestamp with time zone */
        updated_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/margin_accounts`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags margin_accounts
     * @name MarginAccountsList
     * @request GET:/margin_accounts
     * @response `200` `(MarginAccounts)[]` OK
     * @response `206` `unknown` Partial Content
     */
    marginAccountsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format app_data.quantity */
        total_collateral?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<MarginAccounts[], any>({
        path: `/margin_accounts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags margin_accounts
     * @name MarginAccountsPartialUpdate
     * @request PATCH:/margin_accounts
     * @response `204` `unknown` No Content
     */
    marginAccountsPartialUpdate: (
      margin_accounts: MarginAccounts,
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format app_data.quantity */
        total_collateral?: string
        /** @format timestamp with time zone */
        updated_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/margin_accounts`,
        method: 'PATCH',
        query: query,
        body: margin_accounts,
        ...params,
      }),

    /**
     * No description
     *
     * @tags margin_accounts
     * @name MarginAccountsCreate
     * @request POST:/margin_accounts
     * @response `201` `unknown` Created
     */
    marginAccountsCreate: (
      margin_accounts: MarginAccounts,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/margin_accounts`,
        method: 'POST',
        query: query,
        body: margin_accounts,
        ...params,
      }),
  }
  marginBalances = {
    /**
     * No description
     *
     * @tags margin_balances
     * @name MarginBalancesDelete
     * @request DELETE:/margin_balances
     * @response `204` `unknown` No Content
     */
    marginBalancesDelete: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        wallet_id?: string
        /** @format numeric */
        balance?: string
        /** @format numeric */
        free?: string
        /** @format numeric */
        borrowed?: string
        /** @format numeric */
        interest?: string
        /** @format timestamp with time zone */
        updated_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/margin_balances`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags margin_balances
     * @name MarginBalancesList
     * @request GET:/margin_balances
     * @response `200` `(MarginBalances)[]` OK
     * @response `206` `unknown` Partial Content
     */
    marginBalancesList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        wallet_id?: string
        /** @format numeric */
        balance?: string
        /** @format numeric */
        free?: string
        /** @format numeric */
        borrowed?: string
        /** @format numeric */
        interest?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<MarginBalances[], any>({
        path: `/margin_balances`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags margin_balances
     * @name MarginBalancesPartialUpdate
     * @request PATCH:/margin_balances
     * @response `204` `unknown` No Content
     */
    marginBalancesPartialUpdate: (
      margin_balances: MarginBalances,
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        wallet_id?: string
        /** @format numeric */
        balance?: string
        /** @format numeric */
        free?: string
        /** @format numeric */
        borrowed?: string
        /** @format numeric */
        interest?: string
        /** @format timestamp with time zone */
        updated_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/margin_balances`,
        method: 'PATCH',
        query: query,
        body: margin_balances,
        ...params,
      }),

    /**
     * No description
     *
     * @tags margin_balances
     * @name MarginBalancesCreate
     * @request POST:/margin_balances
     * @response `201` `unknown` Created
     */
    marginBalancesCreate: (
      margin_balances: MarginBalances,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/margin_balances`,
        method: 'POST',
        query: query,
        body: margin_balances,
        ...params,
      }),
  }
  marginLimitUsage = {
    /**
     * No description
     *
     * @tags margin_limit_usage
     * @name MarginLimitUsageList
     * @request GET:/margin_limit_usage
     * @response `200` `(MarginLimitUsage)[]` OK
     * @response `206` `unknown` Partial Content
     */
    marginLimitUsageList: (
      query?: {
        /** @format bigint */
        wallet_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format numeric */
        lower_limit_short?: string
        /** @format numeric */
        upper_limit_short?: string
        /** @format numeric */
        lower_limit_long?: string
        /** @format numeric */
        upper_limit_long?: string
        /** @format text */
        note?: string
        /** @format app_data.quantity */
        balance?: string
        /** @format app_data.quantity */
        exposure?: string
        /** @format app_data.quantity */
        long_exposure?: string
        /** @format app_data.quantity */
        short_exposure?: string
        /** @format numeric */
        margin_ratio?: string
        /** @format numeric */
        margin_ratio_long?: string
        /** @format numeric */
        margin_ratio_short?: string
        /** @format double precision */
        margin_limit_proportion?: string
        /** @format double precision */
        margin_limit_proportion_long?: string
        /** @format double precision */
        margin_limit_proportion_short?: string
        /** @format numeric */
        worst_lower_limit?: string
        /** @format app_data.quantity */
        worst_exposure?: string
        /** @format numeric */
        worst_margin_ratio?: string
        /** @format double precision */
        worst_margin_limit_proportion?: string
        /** @format text */
        worst_limit_direction?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
        /** @format public.citext */
        wallet_name?: string
        /** @format app_data.wallet_type */
        wallet_type?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<MarginLimitUsage[], any>({
        path: `/margin_limit_usage`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  marginLimits = {
    /**
     * No description
     *
     * @tags margin_limits
     * @name MarginLimitsDelete
     * @request DELETE:/margin_limits
     * @response `204` `unknown` No Content
     */
    marginLimitsDelete: (
      query?: {
        /** @format bigint */
        wallet_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format numeric */
        lower_limit_short?: string
        /** @format numeric */
        upper_limit_short?: string
        /** @format numeric */
        lower_limit_long?: string
        /** @format numeric */
        upper_limit_long?: string
        /** @format text */
        note?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/margin_limits`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags margin_limits
     * @name MarginLimitsList
     * @request GET:/margin_limits
     * @response `200` `(MarginLimits)[]` OK
     * @response `206` `unknown` Partial Content
     */
    marginLimitsList: (
      query?: {
        /** @format bigint */
        wallet_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format numeric */
        lower_limit_short?: string
        /** @format numeric */
        upper_limit_short?: string
        /** @format numeric */
        lower_limit_long?: string
        /** @format numeric */
        upper_limit_long?: string
        /** @format text */
        note?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<MarginLimits[], any>({
        path: `/margin_limits`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags margin_limits
     * @name MarginLimitsPartialUpdate
     * @request PATCH:/margin_limits
     * @response `204` `unknown` No Content
     */
    marginLimitsPartialUpdate: (
      margin_limits: MarginLimits,
      query?: {
        /** @format bigint */
        wallet_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format numeric */
        lower_limit_short?: string
        /** @format numeric */
        upper_limit_short?: string
        /** @format numeric */
        lower_limit_long?: string
        /** @format numeric */
        upper_limit_long?: string
        /** @format text */
        note?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/margin_limits`,
        method: 'PATCH',
        query: query,
        body: margin_limits,
        ...params,
      }),

    /**
     * No description
     *
     * @tags margin_limits
     * @name MarginLimitsCreate
     * @request POST:/margin_limits
     * @response `201` `unknown` Created
     */
    marginLimitsCreate: (
      margin_limits: MarginLimits,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/margin_limits`,
        method: 'POST',
        query: query,
        body: margin_limits,
        ...params,
      }),
  }
  marginMarketHoldings = {
    /**
     * No description
     *
     * @tags margin_market_holdings
     * @name MarginMarketHoldingsList
     * @request GET:/margin_market_holdings
     * @response `200` `(MarginMarketHoldings)[]` OK
     * @response `206` `unknown` Partial Content
     */
    marginMarketHoldingsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        market_id?: string
        /** @format numeric */
        delta?: string
        /** @format numeric */
        total_asset_balance?: string
        /** @format numeric */
        free_asset_balance?: string
        /** @format numeric */
        total_currency_balance?: string
        /** @format numeric */
        free_currency_balance?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<MarginMarketHoldings[], any>({
        path: `/margin_market_holdings`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  marginOverview = {
    /**
     * No description
     *
     * @tags margin_overview
     * @name MarginOverviewList
     * @request GET:/margin_overview
     * @response `200` `(MarginOverview)[]` OK
     * @response `206` `unknown` Partial Content
     */
    marginOverviewList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format app_data.quantity */
        balance?: string
        /** @format app_data.quantity */
        debt?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<MarginOverview[], any>({
        path: `/margin_overview`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  marginRequests = {
    /**
     * No description
     *
     * @tags margin_requests
     * @name MarginRequestsDelete
     * @request DELETE:/margin_requests
     * @response `204` `unknown` No Content
     */
    marginRequestsDelete: (
      query?: {
        /** @format bigint */
        margin_request_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        wallet_id?: string
        /** @format app_data.margin_event */
        request_type?: string
        /** @format numeric */
        amount?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/margin_requests`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags margin_requests
     * @name MarginRequestsList
     * @request GET:/margin_requests
     * @response `200` `(MarginRequests)[]` OK
     * @response `206` `unknown` Partial Content
     */
    marginRequestsList: (
      query?: {
        /** @format bigint */
        margin_request_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        wallet_id?: string
        /** @format app_data.margin_event */
        request_type?: string
        /** @format numeric */
        amount?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<MarginRequests[], any>({
        path: `/margin_requests`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags margin_requests
     * @name MarginRequestsPartialUpdate
     * @request PATCH:/margin_requests
     * @response `204` `unknown` No Content
     */
    marginRequestsPartialUpdate: (
      margin_requests: MarginRequests,
      query?: {
        /** @format bigint */
        margin_request_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        wallet_id?: string
        /** @format app_data.margin_event */
        request_type?: string
        /** @format numeric */
        amount?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/margin_requests`,
        method: 'PATCH',
        query: query,
        body: margin_requests,
        ...params,
      }),

    /**
     * No description
     *
     * @tags margin_requests
     * @name MarginRequestsCreate
     * @request POST:/margin_requests
     * @response `201` `unknown` Created
     */
    marginRequestsCreate: (
      margin_requests: MarginRequests,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/margin_requests`,
        method: 'POST',
        query: query,
        body: margin_requests,
        ...params,
      }),
  }
  marketProperties = {
    /**
     * No description
     *
     * @tags market_properties
     * @name MarketPropertiesDelete
     * @request DELETE:/market_properties
     * @response `204` `unknown` No Content
     */
    marketPropertiesDelete: (
      query?: {
        /** @format bigint */
        market_id?: string
        /** @format numeric */
        tick_size?: string
        /** @format app_data.tick_size_style */
        tick_size_style?: string
        /** @format numeric */
        tick_size_as_fixed?: string
        /** @format numeric */
        lot_size?: string
        /** @format numeric */
        min_notional?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/market_properties`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags market_properties
     * @name MarketPropertiesList
     * @request GET:/market_properties
     * @response `200` `(MarketProperties)[]` OK
     * @response `206` `unknown` Partial Content
     */
    marketPropertiesList: (
      query?: {
        /** @format bigint */
        market_id?: string
        /** @format numeric */
        tick_size?: string
        /** @format app_data.tick_size_style */
        tick_size_style?: string
        /** @format numeric */
        tick_size_as_fixed?: string
        /** @format numeric */
        lot_size?: string
        /** @format numeric */
        min_notional?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<MarketProperties[], any>({
        path: `/market_properties`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags market_properties
     * @name MarketPropertiesPartialUpdate
     * @request PATCH:/market_properties
     * @response `204` `unknown` No Content
     */
    marketPropertiesPartialUpdate: (
      market_properties: MarketProperties,
      query?: {
        /** @format bigint */
        market_id?: string
        /** @format numeric */
        tick_size?: string
        /** @format app_data.tick_size_style */
        tick_size_style?: string
        /** @format numeric */
        tick_size_as_fixed?: string
        /** @format numeric */
        lot_size?: string
        /** @format numeric */
        min_notional?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/market_properties`,
        method: 'PATCH',
        query: query,
        body: market_properties,
        ...params,
      }),

    /**
     * No description
     *
     * @tags market_properties
     * @name MarketPropertiesCreate
     * @request POST:/market_properties
     * @response `201` `unknown` Created
     */
    marketPropertiesCreate: (
      market_properties: MarketProperties,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/market_properties`,
        method: 'POST',
        query: query,
        body: market_properties,
        ...params,
      }),
  }
  marketSummaries = {
    /**
     * No description
     *
     * @tags market_summaries
     * @name MarketSummariesList
     * @request GET:/market_summaries
     * @response `200` `(MarketSummaries)[]` OK
     * @response `206` `unknown` Partial Content
     */
    marketSummariesList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format bigint */
        underlying_id?: string
        /** @format bigint */
        price_currency_id?: string
        /** @format numeric */
        volume?: string
        /** @format numeric */
        bid_size?: string
        /** @format numeric */
        bid_price?: string
        /** @format numeric */
        ask_size?: string
        /** @format numeric */
        ask_price?: string
        /** @format numeric */
        swm?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<MarketSummaries[], any>({
        path: `/market_summaries`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  markets = {
    /**
     * No description
     *
     * @tags markets
     * @name MarketsList
     * @request GET:/markets
     * @response `200` `(Markets)[]` OK
     * @response `206` `unknown` Partial Content
     */
    marketsList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        exchange?: string
        /** @format bigint */
        asset_id?: string
        /** @format bigint */
        currency_id?: string
        /** @format smallint */
        quantity_precision?: string
        /** @format smallint */
        price_precision?: string
        /** @format bigint */
        price_currency_id?: string
        /** @format boolean */
        expired?: string
        /** @format numeric */
        volume?: string
        /** @format bigint */
        collateral_id?: string
        /** @format bigint */
        underlying_id?: string
        /** @format numeric */
        tick_size?: string
        /** @format numeric */
        lot_size?: string
        /** @format numeric */
        min_notional?: string
        /** @format text */
        api_name?: string
        /** @format boolean */
        disabled?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Markets[], any>({
        path: `/markets`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  openOrders = {
    /**
     * No description
     *
     * @tags open_orders
     * @name OpenOrdersList
     * @request GET:/open_orders
     * @response `200` `(OpenOrders)[]` OK
     * @response `206` `unknown` Partial Content
     */
    openOrdersList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        id?: string
        /** @format bigint */
        market_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format bigint */
        currency_id?: string
        /** @format text */
        id_on_exchange?: string
        /** @format app_data.order_status */
        status?: string
        /** @format numeric */
        price?: string
        /** @format numeric */
        quantity?: string
        /** @format numeric */
        filled_quantity?: string
        /** @format app_data.direction */
        direction?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        trade_request_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<OpenOrders[], any>({
        path: `/open_orders`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  orderHedgeStatus = {
    /**
     * No description
     *
     * @tags order_hedge_status
     * @name OrderHedgeStatusList
     * @request GET:/order_hedge_status
     * @response `200` `(OrderHedgeStatus)[]` OK
     * @response `206` `unknown` Partial Content
     */
    orderHedgeStatusList: (
      query?: {
        /** @format bigint */
        trade_request_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        market_id?: string
        /** @format numeric */
        price?: string
        /** @format numeric */
        quantity?: string
        /** @format app_data.direction */
        direction?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
        /** @format app_data.trade_source */
        source?: string
        /** @format app_data.order_status */
        order_status?: string
        /** @format text */
        error?: string
        /** @format bigint */
        num_fills?: string
        /** @format numeric */
        filled_quantity?: string
        /** @format numeric */
        avg_filled_price?: string
        /** @format bigint */
        hedge_market_id?: string
        /** @format bigint */
        num_hedges_requested?: string
        /** @format numeric */
        hedge_order_quantity?: string
        /** @format numeric */
        hedge_avg_order_price?: string
        /** @format numeric */
        hedge_filled_quantity?: string
        /** @format numeric */
        hedge_avg_filled_price?: string
        /** @format boolean */
        any_hedge_order_open?: string
        /** @format text[] */
        hedge_errors?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderHedgeStatus[], any>({
        path: `/order_hedge_status`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  orders = {
    /**
     * No description
     *
     * @tags orders
     * @name OrdersList
     * @request GET:/orders
     * @response `200` `(Orders)[]` OK
     * @response `206` `unknown` Partial Content
     */
    ordersList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        id?: string
        /** @format bigint */
        market_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format bigint */
        currency_id?: string
        /** @format text */
        id_on_exchange?: string
        /** @format app_data.order_status */
        status?: string
        /** @format numeric */
        price?: string
        /** @format numeric */
        quantity?: string
        /** @format numeric */
        filled_quantity?: string
        /** @format app_data.direction */
        direction?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        trade_request_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Orders[], any>({
        path: `/orders`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  ping = {
    /**
     * No description
     *
     * @tags ping
     * @name PingList
     * @request GET:/ping
     * @response `200` `(Ping)[]` OK
     * @response `206` `unknown` Partial Content
     */
    pingList: (
      query?: {
        /** @format text */
        response?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Ping[], any>({
        path: `/ping`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  positionUpnl = {
    /**
     * No description
     *
     * @tags position_upnl
     * @name PositionUpnlList
     * @request GET:/position_upnl
     * @response `200` `(PositionUpnl)[]` OK
     * @response `206` `unknown` Partial Content
     */
    positionUpnlList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format text */
        exchange?: string
        /** @format bigint */
        asset_id?: string
        /** @format bigint */
        currency_id?: string
        /** @format numeric */
        position?: string
        /** @format numeric */
        entry_price?: string
        /** @format numeric */
        derived_upnl?: string
        /** @format timestamp with time zone */
        derived_upnl_updated_at?: string
        /** @format numeric */
        exchange_upnl?: string
        /** @format timestamp with time zone */
        exchange_upnl_updated_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<PositionUpnl[], any>({
        path: `/position_upnl`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  positions = {
    /**
     * No description
     *
     * @tags positions
     * @name PositionsList
     * @request GET:/positions
     * @response `200` `(Positions)[]` OK
     * @response `206` `unknown` Partial Content
     */
    positionsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        position?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format text */
        exchange?: string
        /** @format app_data.quantity_t */
        mark_price?: string
        /** @format app_data.quantity_t */
        expected_pnl?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Positions[], any>({
        path: `/positions`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  predictionApprovals = {
    /**
     * No description
     *
     * @tags prediction_approvals
     * @name PredictionApprovalsDelete
     * @request DELETE:/prediction_approvals
     * @response `204` `unknown` No Content
     */
    predictionApprovalsDelete: (
      query?: {
        /** @format bigint */
        prediction_id?: string
        /** @format boolean */
        approved?: string
        /** @format bigint */
        approved_by_id?: string
        /** @format timestamp with time zone */
        approved_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/prediction_approvals`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags prediction_approvals
     * @name PredictionApprovalsList
     * @request GET:/prediction_approvals
     * @response `200` `(PredictionApprovals)[]` OK
     * @response `206` `unknown` Partial Content
     */
    predictionApprovalsList: (
      query?: {
        /** @format bigint */
        prediction_id?: string
        /** @format boolean */
        approved?: string
        /** @format bigint */
        approved_by_id?: string
        /** @format timestamp with time zone */
        approved_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<PredictionApprovals[], any>({
        path: `/prediction_approvals`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags prediction_approvals
     * @name PredictionApprovalsPartialUpdate
     * @request PATCH:/prediction_approvals
     * @response `204` `unknown` No Content
     */
    predictionApprovalsPartialUpdate: (
      prediction_approvals: PredictionApprovals,
      query?: {
        /** @format bigint */
        prediction_id?: string
        /** @format boolean */
        approved?: string
        /** @format bigint */
        approved_by_id?: string
        /** @format timestamp with time zone */
        approved_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/prediction_approvals`,
        method: 'PATCH',
        query: query,
        body: prediction_approvals,
        ...params,
      }),

    /**
     * No description
     *
     * @tags prediction_approvals
     * @name PredictionApprovalsCreate
     * @request POST:/prediction_approvals
     * @response `201` `unknown` Created
     */
    predictionApprovalsCreate: (
      prediction_approvals: PredictionApprovals,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/prediction_approvals`,
        method: 'POST',
        query: query,
        body: prediction_approvals,
        ...params,
      }),
  }
  predictionComments = {
    /**
     * No description
     *
     * @tags prediction_comments
     * @name PredictionCommentsDelete
     * @request DELETE:/prediction_comments
     * @response `204` `unknown` No Content
     */
    predictionCommentsDelete: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        body?: string
        /** @format bigint */
        prediction_id?: string
        /** @format bigint */
        author_id?: string
        /** @format text */
        author?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        updated_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/prediction_comments`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags prediction_comments
     * @name PredictionCommentsList
     * @request GET:/prediction_comments
     * @response `200` `(PredictionComments)[]` OK
     * @response `206` `unknown` Partial Content
     */
    predictionCommentsList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        body?: string
        /** @format bigint */
        prediction_id?: string
        /** @format bigint */
        author_id?: string
        /** @format text */
        author?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<PredictionComments[], any>({
        path: `/prediction_comments`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags prediction_comments
     * @name PredictionCommentsPartialUpdate
     * @request PATCH:/prediction_comments
     * @response `204` `unknown` No Content
     */
    predictionCommentsPartialUpdate: (
      prediction_comments: PredictionComments,
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        body?: string
        /** @format bigint */
        prediction_id?: string
        /** @format bigint */
        author_id?: string
        /** @format text */
        author?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        updated_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/prediction_comments`,
        method: 'PATCH',
        query: query,
        body: prediction_comments,
        ...params,
      }),

    /**
     * No description
     *
     * @tags prediction_comments
     * @name PredictionCommentsCreate
     * @request POST:/prediction_comments
     * @response `201` `unknown` Created
     */
    predictionCommentsCreate: (
      prediction_comments: PredictionComments,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/prediction_comments`,
        method: 'POST',
        query: query,
        body: prediction_comments,
        ...params,
      }),
  }
  predictionExport = {
    /**
     * No description
     *
     * @tags prediction_export
     * @name PredictionExportList
     * @request GET:/prediction_export
     * @response `200` `(PredictionExport)[]` OK
     * @response `206` `unknown` Partial Content
     */
    predictionExportList: (
      query?: {
        /** @format public.citext */
        asset?: string
        /** @format public.citext */
        base?: string
        /** @format numeric */
        expected_move?: string
        /** @format numeric */
        actual_move?: string
        /** @format text */
        username?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        expires_at?: string
        /** @format timestamp with time zone */
        closed_at?: string
        /** @format integer */
        confidence?: string
        /** @format numeric */
        size?: string
        /** @format numeric */
        stop_loss?: string
        /** @format numeric */
        take_profit?: string
        /** @format numeric */
        start_fair?: string
        /** @format numeric */
        close_fair?: string
        /** @format numeric */
        profit?: string
        /** @format boolean */
        approved?: string
        /** @format text */
        comment?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<PredictionExport[], any>({
        path: `/prediction_export`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  predictions = {
    /**
     * No description
     *
     * @tags predictions
     * @name PredictionsDelete
     * @request DELETE:/predictions
     * @response `204` `unknown` No Content
     */
    predictionsDelete: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format bigint */
        asset_id?: string
        /** @format bigint */
        base_id?: string
        /** @format numeric */
        expected_move?: string
        /** @format bigint */
        created_by_id?: string
        /** @format interval */
        duration?: string
        /** @format integer */
        confidence?: string
        /** @format text */
        comment?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format numeric */
        size?: string
        /** @format numeric */
        stop_loss?: string
        /** @format timestamp with time zone */
        closed_at?: string
        /** @format numeric */
        take_profit?: string
        /** @format boolean */
        traded?: string
        /** @format boolean */
        not_tradeable?: string
        /** @format numeric */
        start_fair?: string
        /** @format numeric */
        close_fair?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/predictions`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags predictions
     * @name PredictionsList
     * @request GET:/predictions
     * @response `200` `(Predictions)[]` OK
     * @response `206` `unknown` Partial Content
     */
    predictionsList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format bigint */
        asset_id?: string
        /** @format bigint */
        base_id?: string
        /** @format numeric */
        expected_move?: string
        /** @format bigint */
        created_by_id?: string
        /** @format interval */
        duration?: string
        /** @format integer */
        confidence?: string
        /** @format text */
        comment?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format numeric */
        size?: string
        /** @format numeric */
        stop_loss?: string
        /** @format timestamp with time zone */
        closed_at?: string
        /** @format numeric */
        take_profit?: string
        /** @format boolean */
        traded?: string
        /** @format boolean */
        not_tradeable?: string
        /** @format numeric */
        start_fair?: string
        /** @format numeric */
        close_fair?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Predictions[], any>({
        path: `/predictions`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags predictions
     * @name PredictionsPartialUpdate
     * @request PATCH:/predictions
     * @response `204` `unknown` No Content
     */
    predictionsPartialUpdate: (
      predictions: Predictions,
      query?: {
        /** @format bigint */
        id?: string
        /** @format bigint */
        asset_id?: string
        /** @format bigint */
        base_id?: string
        /** @format numeric */
        expected_move?: string
        /** @format bigint */
        created_by_id?: string
        /** @format interval */
        duration?: string
        /** @format integer */
        confidence?: string
        /** @format text */
        comment?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format numeric */
        size?: string
        /** @format numeric */
        stop_loss?: string
        /** @format timestamp with time zone */
        closed_at?: string
        /** @format numeric */
        take_profit?: string
        /** @format boolean */
        traded?: string
        /** @format boolean */
        not_tradeable?: string
        /** @format numeric */
        start_fair?: string
        /** @format numeric */
        close_fair?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/predictions`,
        method: 'PATCH',
        query: query,
        body: predictions,
        ...params,
      }),

    /**
     * No description
     *
     * @tags predictions
     * @name PredictionsCreate
     * @request POST:/predictions
     * @response `201` `unknown` Created
     */
    predictionsCreate: (
      predictions: Predictions,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/predictions`,
        method: 'POST',
        query: query,
        body: predictions,
        ...params,
      }),
  }
  predictionsPnl = {
    /**
     * No description
     *
     * @tags predictions_pnl
     * @name PredictionsPnlList
     * @request GET:/predictions_pnl
     * @response `200` `(PredictionsPnl)[]` OK
     * @response `206` `unknown` Partial Content
     */
    predictionsPnlList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format bigint */
        asset_id?: string
        /** @format bigint */
        base_id?: string
        /** @format numeric */
        expected_move?: string
        /** @format numeric */
        actual_move?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        expires_at?: string
        /** @format boolean */
        open?: string
        /** @format text */
        created_by?: string
        /** @format boolean */
        closeable?: string
        /** @format integer */
        confidence?: string
        /** @format text */
        comment?: string
        /** @format numeric */
        size?: string
        /** @format numeric */
        stop_loss?: string
        /** @format timestamp with time zone */
        closed_at?: string
        /** @format numeric */
        take_profit?: string
        /** @format numeric */
        start_fair?: string
        /** @format numeric */
        close_fair?: string
        /** @format numeric */
        profit?: string
        /** @format bigint */
        comments?: string
        /** @format bigint */
        trade_group_id?: string
        /** @format bigint */
        fills?: string
        /** @format boolean */
        approved?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<PredictionsPnl[], any>({
        path: `/predictions_pnl`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  quotePremia = {
    /**
     * No description
     *
     * @tags quote_premia
     * @name QuotePremiaList
     * @request GET:/quote_premia
     * @response `200` `(QuotePremia)[]` OK
     * @response `206` `unknown` Partial Content
     */
    quotePremiaList: (
      query?: {
        /** @format bigint */
        market_id?: string
        /** @format bigint */
        hedge_market_id?: string
        /** @format numeric */
        premium?: string
        /** @format timestamp with time zone */
        ts?: string
        /** @format numeric */
        swap_bid_price?: string
        /** @format numeric */
        swap_ask_price?: string
        /** @format numeric */
        spot_bid_price?: string
        /** @format numeric */
        spot_ask_price?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<QuotePremia[], any>({
        path: `/quote_premia`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  recentCumulativeFunding = {
    /**
     * No description
     *
     * @tags recent_cumulative_funding
     * @name RecentCumulativeFundingList
     * @request GET:/recent_cumulative_funding
     * @response `200` `(RecentCumulativeFunding)[]` OK
     * @response `206` `unknown` Partial Content
     */
    recentCumulativeFundingList: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format public.citext */
        symbol?: string
        /** @format numeric */
        total_1d?: string
        /** @format numeric */
        total_3d?: string
        /** @format numeric */
        total_7d?: string
        /** @format bigint */
        num_component_rates_1d?: string
        /** @format bigint */
        num_component_rates_3d?: string
        /** @format bigint */
        num_component_rates_7d?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<RecentCumulativeFunding[], any>({
        path: `/recent_cumulative_funding`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  recentTwaps = {
    /**
     * No description
     *
     * @tags recent_twaps
     * @name RecentTwapsList
     * @request GET:/recent_twaps
     * @response `200` `(RecentTwaps)[]` OK
     * @response `206` `unknown` Partial Content
     */
    recentTwapsList: (
      query?: {
        /** @format bigint */
        twap_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        creator_id?: string
        /** @format interval */
        duration?: string
        /** @format interval */
        frequency?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        started_at?: string
        /** @format timestamp with time zone */
        ended_at?: string
        /** @format algo.twap_status */
        status?: string
        /** @format integer */
        num_orders?: string
        /** @format bigint */
        underlying_id?: string
        /** @format bigint[] */
        market_ids?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<RecentTwaps[], any>({
        path: `/recent_twaps`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  recommendedSizes = {
    /**
     * No description
     *
     * @tags recommended_sizes
     * @name RecommendedSizesList
     * @request GET:/recommended_sizes
     * @response `200` `(RecommendedSizes)[]` OK
     * @response `206` `unknown` Partial Content
     */
    recommendedSizesList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        recommended_size?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<RecommendedSizes[], any>({
        path: `/recommended_sizes`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  rpc = {
    /**
     * No description
     *
     * @tags (rpc) cancel_my_orders
     * @name CancelMyOrdersList
     * @request GET:/rpc/cancel_my_orders
     * @response `200` `unknown` OK
     */
    cancelMyOrdersList: (params: RequestParams = {}) =>
      this.request<unknown, any>({
        path: `/rpc/cancel_my_orders`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) cancel_my_orders
     * @name CancelMyOrdersCreate
     * @request POST:/rpc/cancel_my_orders
     * @response `200` `unknown` OK
     */
    cancelMyOrdersCreate: (args: object, params: RequestParams = {}) =>
      this.request<unknown, any>({
        path: `/rpc/cancel_my_orders`,
        method: 'POST',
        body: args,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) clone_twap
     * @name CloneTwapList
     * @request GET:/rpc/clone_twap
     * @response `200` `unknown` OK
     */
    cloneTwapList: (
      query: {
        /** @format bigint */
        twap_id: number
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/clone_twap`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) clone_twap
     * @name CloneTwapCreate
     * @request POST:/rpc/clone_twap
     * @response `200` `unknown` OK
     */
    cloneTwapCreate: (
      args: {
        /** @format bigint */
        twap_id: number
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/clone_twap`,
        method: 'POST',
        body: args,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) create_tenant_credentials
     * @name CreateTenantCredentialsList
     * @request GET:/rpc/create_tenant_credentials
     * @response `200` `unknown` OK
     */
    createTenantCredentialsList: (
      query: {
        /** @format tenant_credentials */
        creds: string
        /** @format boolean */
        replace: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/create_tenant_credentials`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) create_tenant_credentials
     * @name CreateTenantCredentialsCreate
     * @request POST:/rpc/create_tenant_credentials
     * @response `200` `unknown` OK
     */
    createTenantCredentialsCreate: (
      args: {
        /** @format tenant_credentials */
        creds: string
        /** @format boolean */
        replace: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/create_tenant_credentials`,
        method: 'POST',
        body: args,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) current_user_id
     * @name CurrentUserIdList
     * @request GET:/rpc/current_user_id
     * @response `200` `unknown` OK
     */
    currentUserIdList: (params: RequestParams = {}) =>
      this.request<unknown, any>({
        path: `/rpc/current_user_id`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) current_user_id
     * @name CurrentUserIdCreate
     * @request POST:/rpc/current_user_id
     * @response `200` `unknown` OK
     */
    currentUserIdCreate: (args: object, params: RequestParams = {}) =>
      this.request<unknown, any>({
        path: `/rpc/current_user_id`,
        method: 'POST',
        body: args,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) daily_average_binance_balances
     * @name DailyAverageBinanceBalancesList
     * @request GET:/rpc/daily_average_binance_balances
     * @response `200` `unknown` OK
     */
    dailyAverageBinanceBalancesList: (
      query: {
        /** @format date */
        start_date: string
        /** @format date */
        end_date: string
        /** @format bigint */
        asset_id: number
        /** @format wallet_type */
        wallet_type: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/daily_average_binance_balances`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) daily_average_binance_balances
     * @name DailyAverageBinanceBalancesCreate
     * @request POST:/rpc/daily_average_binance_balances
     * @response `200` `unknown` OK
     */
    dailyAverageBinanceBalancesCreate: (
      args: {
        /** @format bigint */
        asset_id: number
        /** @format date */
        end_date: string
        /** @format date */
        start_date: string
        /** @format wallet_type */
        wallet_type: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/daily_average_binance_balances`,
        method: 'POST',
        body: args,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) daily_average_binance_spot_balances
     * @name DailyAverageBinanceSpotBalancesList
     * @request GET:/rpc/daily_average_binance_spot_balances
     * @response `200` `unknown` OK
     */
    dailyAverageBinanceSpotBalancesList: (
      query: {
        /** @format date */
        start_date: string
        /** @format date */
        end_date: string
        /** @format bigint */
        asset_id: number
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/daily_average_binance_spot_balances`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) daily_average_binance_spot_balances
     * @name DailyAverageBinanceSpotBalancesCreate
     * @request POST:/rpc/daily_average_binance_spot_balances
     * @response `200` `unknown` OK
     */
    dailyAverageBinanceSpotBalancesCreate: (
      args: {
        /** @format bigint */
        asset_id: number
        /** @format date */
        end_date: string
        /** @format date */
        start_date: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/daily_average_binance_spot_balances`,
        method: 'POST',
        body: args,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) delta_limits_report
     * @name DeltaLimitsReportList
     * @request GET:/rpc/delta_limits_report
     * @response `200` `unknown` OK
     */
    deltaLimitsReportList: (
      query: {
        /** @format timestamp with time zone */
        first_logged_at: string
        /** @format timestamp with time zone */
        last_logged_at: string
        /** @format bigint */
        tenant_id?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/delta_limits_report`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) delta_limits_report
     * @name DeltaLimitsReportCreate
     * @request POST:/rpc/delta_limits_report
     * @response `200` `unknown` OK
     */
    deltaLimitsReportCreate: (
      args: {
        /** @format timestamp with time zone */
        first_logged_at: string
        /** @format timestamp with time zone */
        last_logged_at: string
        /** @format bigint */
        tenant_id?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/delta_limits_report`,
        method: 'POST',
        body: args,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) funding_history
     * @name FundingHistoryList
     * @request GET:/rpc/funding_history
     * @response `200` `unknown` OK
     */
    fundingHistoryList: (
      query: {
        /** @format timestamp with time zone */
        start_time: string
        /** @format timestamp with time zone */
        end_time: string
        /** @format bigint */
        tenant_id: number
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/funding_history`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) funding_history
     * @name FundingHistoryCreate
     * @request POST:/rpc/funding_history
     * @response `200` `unknown` OK
     */
    fundingHistoryCreate: (
      args: {
        /** @format timestamp with time zone */
        end_time: string
        /** @format timestamp with time zone */
        start_time: string
        /** @format bigint */
        tenant_id: number
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/funding_history`,
        method: 'POST',
        body: args,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) get_delta_limits_inferred_data
     * @name GetDeltaLimitsInferredDataList
     * @request GET:/rpc/get_delta_limits_inferred_data
     * @response `200` `unknown` OK
     */
    getDeltaLimitsInferredDataList: (
      query: {
        /** @format numeric */
        delta_limit_lower: number
        /** @format numeric */
        delta_limit_upper: number
        /** @format numeric */
        delta: number
        /** @format numeric */
        delta_btc: number
        /** @format numeric */
        target_delta_btc: number
        /** @format numeric */
        target_delta_in_delta_currency: number
        /** @format numeric */
        aum_btc: number
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/get_delta_limits_inferred_data`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) get_delta_limits_inferred_data
     * @name GetDeltaLimitsInferredDataCreate
     * @request POST:/rpc/get_delta_limits_inferred_data
     * @response `200` `unknown` OK
     */
    getDeltaLimitsInferredDataCreate: (
      args: {
        /** @format numeric */
        aum_btc: number
        /** @format numeric */
        delta: number
        /** @format numeric */
        delta_btc: number
        /** @format numeric */
        delta_limit_lower: number
        /** @format numeric */
        delta_limit_upper: number
        /** @format numeric */
        target_delta_btc: number
        /** @format numeric */
        target_delta_in_delta_currency: number
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/get_delta_limits_inferred_data`,
        method: 'POST',
        body: args,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) historical_balance_snapshot_extended
     * @name HistoricalBalanceSnapshotExtendedList
     * @request GET:/rpc/historical_balance_snapshot_extended
     * @response `200` `unknown` OK
     */
    historicalBalanceSnapshotExtendedList: (
      query: {
        /** @format timestamp with time zone */
        ts: string
        /** @format bigint */
        tenant_id: number
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/historical_balance_snapshot_extended`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) historical_balance_snapshot_extended
     * @name HistoricalBalanceSnapshotExtendedCreate
     * @request POST:/rpc/historical_balance_snapshot_extended
     * @response `200` `unknown` OK
     */
    historicalBalanceSnapshotExtendedCreate: (
      args: {
        /** @format bigint */
        tenant_id: number
        /** @format timestamp with time zone */
        ts: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/historical_balance_snapshot_extended`,
        method: 'POST',
        body: args,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) historical_mark_prices
     * @name HistoricalMarkPricesList
     * @request GET:/rpc/historical_mark_prices
     * @response `200` `unknown` OK
     */
    historicalMarkPricesList: (
      query: {
        /** @format timestamp with time zone */
        ts: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/historical_mark_prices`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) historical_mark_prices
     * @name HistoricalMarkPricesCreate
     * @request POST:/rpc/historical_mark_prices
     * @response `200` `unknown` OK
     */
    historicalMarkPricesCreate: (
      args: {
        /** @format timestamp with time zone */
        ts: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/historical_mark_prices`,
        method: 'POST',
        body: args,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) historical_navs
     * @name HistoricalNavsList
     * @request GET:/rpc/historical_navs
     * @response `200` `unknown` OK
     */
    historicalNavsList: (
      query: {
        /** @format timestamp with time zone */
        ts: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/historical_navs`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) historical_navs
     * @name HistoricalNavsCreate
     * @request POST:/rpc/historical_navs
     * @response `200` `unknown` OK
     */
    historicalNavsCreate: (
      args: {
        /** @format timestamp with time zone */
        ts: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/historical_navs`,
        method: 'POST',
        body: args,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) margin_limits_report
     * @name MarginLimitsReportList
     * @request GET:/rpc/margin_limits_report
     * @response `200` `unknown` OK
     */
    marginLimitsReportList: (
      query: {
        /** @format timestamp with time zone */
        first_logged_at: string
        /** @format timestamp with time zone */
        last_logged_at: string
        /** @format bigint */
        tenant_id?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/margin_limits_report`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) margin_limits_report
     * @name MarginLimitsReportCreate
     * @request POST:/rpc/margin_limits_report
     * @response `200` `unknown` OK
     */
    marginLimitsReportCreate: (
      args: {
        /** @format timestamp with time zone */
        first_logged_at: string
        /** @format timestamp with time zone */
        last_logged_at: string
        /** @format bigint */
        tenant_id?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/margin_limits_report`,
        method: 'POST',
        body: args,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) recent_twaps
     * @name RecentTwapsList
     * @request GET:/rpc/recent_twaps
     * @response `200` `unknown` OK
     */
    recentTwapsList: (
      query: {
        /** @format bigint */
        tenant_id: number
        /** @format smallint */
        per_page?: number
        /** @format smallint */
        page_number?: number
        /** @format boolean */
        show_inactive?: boolean
        /** @format smallint */
        creator_id?: number
        /** @format interval */
        recency_threshold?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/recent_twaps`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) recent_twaps
     * @name RecentTwapsCreate
     * @request POST:/rpc/recent_twaps
     * @response `200` `unknown` OK
     */
    recentTwapsCreate: (
      args: {
        /** @format smallint */
        creator_id?: number
        /** @format smallint */
        page_number?: number
        /** @format smallint */
        per_page?: number
        /** @format interval */
        recency_threshold?: string
        /** @format boolean */
        show_inactive?: boolean
        /** @format bigint */
        tenant_id: number
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/recent_twaps`,
        method: 'POST',
        body: args,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) request_trade
     * @name RequestTradeList
     * @request GET:/rpc/request_trade
     * @response `200` `unknown` OK
     */
    requestTradeList: (
      query: {
        /** @format bigint */
        tenant_id: number
        /** @format bigint */
        market_id: number
        /** @format numeric */
        price: number
        /** @format numeric */
        quantity: number
        /** @format direction */
        direction: string
        /** @format time_in_force */
        time_in_force: string
        /** @format boolean */
        post_only: boolean
        /** @format bigint */
        strategy_id: number
        /** @format margin_mode */
        margin_mode: string
        /** @format bigint */
        hedge_market_id: number
        /** @format margin_mode */
        hedge_margin_mode: string
        /** @format integer */
        hedge_delay: number
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/request_trade`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) request_trade
     * @name RequestTradeCreate
     * @request POST:/rpc/request_trade
     * @response `200` `unknown` OK
     */
    requestTradeCreate: (
      args: {
        /** @format direction */
        direction: string
        /** @format integer */
        hedge_delay: number
        /** @format margin_mode */
        hedge_margin_mode: string
        /** @format bigint */
        hedge_market_id: number
        /** @format margin_mode */
        margin_mode: string
        /** @format bigint */
        market_id: number
        /** @format boolean */
        post_only: boolean
        /** @format numeric */
        price: number
        /** @format numeric */
        quantity: number
        /** @format bigint */
        strategy_id: number
        /** @format bigint */
        tenant_id: number
        /** @format time_in_force */
        time_in_force: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/request_trade`,
        method: 'POST',
        body: args,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) summarize_fills
     * @name SummarizeFillsList
     * @request GET:/rpc/summarize_fills
     * @response `200` `unknown` OK
     */
    summarizeFillsList: (
      query: {
        /** @format timestamp with time zone */
        start_time: string
        /** @format timestamp with time zone */
        end_time: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/summarize_fills`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) summarize_fills
     * @name SummarizeFillsCreate
     * @request POST:/rpc/summarize_fills
     * @response `200` `unknown` OK
     */
    summarizeFillsCreate: (
      args: {
        /** @format timestamp with time zone */
        end_time: string
        /** @format timestamp with time zone */
        start_time: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/summarize_fills`,
        method: 'POST',
        body: args,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) twap_leg_summaries
     * @name TwapLegSummariesList
     * @request GET:/rpc/twap_leg_summaries
     * @response `200` `unknown` OK
     */
    twapLegSummariesList: (
      query: {
        /** @format bigint[] */
        twap_ids: string
        /** @format interval */
        recency_threshold?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/twap_leg_summaries`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags (rpc) twap_leg_summaries
     * @name TwapLegSummariesCreate
     * @request POST:/rpc/twap_leg_summaries
     * @response `200` `unknown` OK
     */
    twapLegSummariesCreate: (
      args: {
        /** @format interval */
        recency_threshold?: string
        /** @format bigint[] */
        twap_ids: number[]
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/rpc/twap_leg_summaries`,
        method: 'POST',
        body: args,
        type: ContentType.Json,
        ...params,
      }),
  }
  slackTargets = {
    /**
     * No description
     *
     * @tags slack_targets
     * @name SlackTargetsDelete
     * @request DELETE:/slack_targets
     * @response `204` `unknown` No Content
     */
    slackTargetsDelete: (
      query?: {
        /** @format text */
        target?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/slack_targets`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags slack_targets
     * @name SlackTargetsList
     * @request GET:/slack_targets
     * @response `200` `(SlackTargets)[]` OK
     * @response `206` `unknown` Partial Content
     */
    slackTargetsList: (
      query?: {
        /** @format text */
        target?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<SlackTargets[], any>({
        path: `/slack_targets`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags slack_targets
     * @name SlackTargetsPartialUpdate
     * @request PATCH:/slack_targets
     * @response `204` `unknown` No Content
     */
    slackTargetsPartialUpdate: (
      slack_targets: SlackTargets,
      query?: {
        /** @format text */
        target?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/slack_targets`,
        method: 'PATCH',
        query: query,
        body: slack_targets,
        ...params,
      }),

    /**
     * No description
     *
     * @tags slack_targets
     * @name SlackTargetsCreate
     * @request POST:/slack_targets
     * @response `201` `unknown` Created
     */
    slackTargetsCreate: (
      slack_targets: SlackTargets,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/slack_targets`,
        method: 'POST',
        query: query,
        body: slack_targets,
        ...params,
      }),
  }
  spotMarketHoldings = {
    /**
     * No description
     *
     * @tags spot_market_holdings
     * @name SpotMarketHoldingsList
     * @request GET:/spot_market_holdings
     * @response `200` `(SpotMarketHoldings)[]` OK
     * @response `206` `unknown` Partial Content
     */
    spotMarketHoldingsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        market_id?: string
        /** @format numeric */
        delta?: string
        /** @format numeric */
        total_asset_balance?: string
        /** @format numeric */
        free_asset_balance?: string
        /** @format numeric */
        total_currency_balance?: string
        /** @format numeric */
        free_currency_balance?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<SpotMarketHoldings[], any>({
        path: `/spot_market_holdings`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  strategies = {
    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesDelete
     * @request DELETE:/strategies
     * @response `204` `unknown` No Content
     */
    strategiesDelete: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/strategies`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesList
     * @request GET:/strategies
     * @response `200` `(Strategies)[]` OK
     * @response `206` `unknown` Partial Content
     */
    strategiesList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Strategies[], any>({
        path: `/strategies`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesPartialUpdate
     * @request PATCH:/strategies
     * @response `204` `unknown` No Content
     */
    strategiesPartialUpdate: (
      strategies: Strategies,
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/strategies`,
        method: 'PATCH',
        query: query,
        body: strategies,
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesCreate
     * @request POST:/strategies
     * @response `201` `unknown` Created
     */
    strategiesCreate: (
      strategies: Strategies,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/strategies`,
        method: 'POST',
        query: query,
        body: strategies,
        ...params,
      }),
  }
  tags = {
    /**
     * No description
     *
     * @tags tags
     * @name TagsDelete
     * @request DELETE:/tags
     * @response `204` `unknown` No Content
     */
    tagsDelete: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format public.citext */
        name?: string
        /** @format timestamp with time zone */
        created_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/tags`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags
     * @name TagsList
     * @request GET:/tags
     * @response `200` `(Tags)[]` OK
     * @response `206` `unknown` Partial Content
     */
    tagsList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format public.citext */
        name?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Tags[], any>({
        path: `/tags`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags
     * @name TagsPartialUpdate
     * @request PATCH:/tags
     * @response `204` `unknown` No Content
     */
    tagsPartialUpdate: (
      tags: Tags,
      query?: {
        /** @format bigint */
        id?: string
        /** @format public.citext */
        name?: string
        /** @format timestamp with time zone */
        created_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/tags`,
        method: 'PATCH',
        query: query,
        body: tags,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags
     * @name TagsCreate
     * @request POST:/tags
     * @response `201` `unknown` Created
     */
    tagsCreate: (
      tags: Tags,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/tags`,
        method: 'POST',
        query: query,
        body: tags,
        ...params,
      }),
  }
  targetDeltas = {
    /**
     * No description
     *
     * @tags target_deltas
     * @name TargetDeltasDelete
     * @request DELETE:/target_deltas
     * @response `204` `unknown` No Content
     */
    targetDeltasDelete: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
        /** @format bigint */
        asset_id?: string
        /** @format app_data.quantity */
        delta?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/target_deltas`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags target_deltas
     * @name TargetDeltasList
     * @request GET:/target_deltas
     * @response `200` `(TargetDeltas)[]` OK
     * @response `206` `unknown` Partial Content
     */
    targetDeltasList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
        /** @format bigint */
        asset_id?: string
        /** @format app_data.quantity */
        delta?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<TargetDeltas[], any>({
        path: `/target_deltas`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags target_deltas
     * @name TargetDeltasPartialUpdate
     * @request PATCH:/target_deltas
     * @response `204` `unknown` No Content
     */
    targetDeltasPartialUpdate: (
      target_deltas: TargetDeltas,
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
        /** @format bigint */
        asset_id?: string
        /** @format app_data.quantity */
        delta?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** @format bigint */
        updated_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/target_deltas`,
        method: 'PATCH',
        query: query,
        body: target_deltas,
        ...params,
      }),

    /**
     * No description
     *
     * @tags target_deltas
     * @name TargetDeltasCreate
     * @request POST:/target_deltas
     * @response `201` `unknown` Created
     */
    targetDeltasCreate: (
      target_deltas: TargetDeltas,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/target_deltas`,
        method: 'POST',
        query: query,
        body: target_deltas,
        ...params,
      }),
  }
  tenantCredentials = {
    /**
     * No description
     *
     * @tags tenant_credentials
     * @name TenantCredentialsDelete
     * @request DELETE:/tenant_credentials
     * @response `204` `unknown` No Content
     */
    tenantCredentialsDelete: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        api_id?: string
        /** @format text */
        api_key?: string
        /** @format text */
        api_secret?: string
        /** @format text */
        username?: string
        /** @format text */
        password?: string
        /** @format timestamp with time zone */
        created_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/tenant_credentials`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenant_credentials
     * @name TenantCredentialsList
     * @request GET:/tenant_credentials
     * @response `200` `(TenantCredentials)[]` OK
     * @response `206` `unknown` Partial Content
     */
    tenantCredentialsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        api_id?: string
        /** @format text */
        api_key?: string
        /** @format text */
        api_secret?: string
        /** @format text */
        username?: string
        /** @format text */
        password?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<TenantCredentials[], any>({
        path: `/tenant_credentials`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenant_credentials
     * @name TenantCredentialsPartialUpdate
     * @request PATCH:/tenant_credentials
     * @response `204` `unknown` No Content
     */
    tenantCredentialsPartialUpdate: (
      tenant_credentials: TenantCredentials,
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        api_id?: string
        /** @format text */
        api_key?: string
        /** @format text */
        api_secret?: string
        /** @format text */
        username?: string
        /** @format text */
        password?: string
        /** @format timestamp with time zone */
        created_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/tenant_credentials`,
        method: 'PATCH',
        query: query,
        body: tenant_credentials,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenant_credentials
     * @name TenantCredentialsCreate
     * @request POST:/tenant_credentials
     * @response `201` `unknown` Created
     */
    tenantCredentialsCreate: (
      tenant_credentials: TenantCredentials,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/tenant_credentials`,
        method: 'POST',
        query: query,
        body: tenant_credentials,
        ...params,
      }),
  }
  tenantDescendants = {
    /**
     * No description
     *
     * @tags tenant_descendants
     * @name TenantDescendantsList
     * @request GET:/tenant_descendants
     * @response `200` `(TenantDescendants)[]` OK
     * @response `206` `unknown` Partial Content
     */
    tenantDescendantsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint[] */
        descendant_ids?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<TenantDescendants[], any>({
        path: `/tenant_descendants`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  tenants = {
    /**
     * No description
     *
     * @tags tenants
     * @name TenantsDelete
     * @request DELETE:/tenants
     * @response `204` `unknown` No Content
     */
    tenantsDelete: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
        /** @format bigint */
        parent_id?: string
        /** @format timestamp with time zone */
        created_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/tenants`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name TenantsList
     * @request GET:/tenants
     * @response `200` `(Tenants)[]` OK
     * @response `206` `unknown` Partial Content
     */
    tenantsList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
        /** @format bigint */
        parent_id?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Tenants[], any>({
        path: `/tenants`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name TenantsPartialUpdate
     * @request PATCH:/tenants
     * @response `204` `unknown` No Content
     */
    tenantsPartialUpdate: (
      tenants: Tenants,
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
        /** @format bigint */
        parent_id?: string
        /** @format timestamp with time zone */
        created_at?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/tenants`,
        method: 'PATCH',
        query: query,
        body: tenants,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name TenantsCreate
     * @request POST:/tenants
     * @response `201` `unknown` Created
     */
    tenantsCreate: (
      tenants: Tenants,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/tenants`,
        method: 'POST',
        query: query,
        body: tenants,
        ...params,
      }),
  }
  tradeGroupFills = {
    /**
     * No description
     *
     * @tags trade_group_fills
     * @name TradeGroupFillsList
     * @request GET:/trade_group_fills
     * @response `200` `(TradeGroupFills)[]` OK
     * @response `206` `unknown` Partial Content
     */
    tradeGroupFillsList: (
      query?: {
        /** @format bigint */
        trade_group_id?: string
        /** @format bigint */
        market_id?: string
        /** @format app_data.direction */
        direction?: string
        /** @format numeric */
        quantity?: string
        /** @format numeric */
        price?: string
        /** @format timestamp with time zone */
        start_time?: string
        /** @format timestamp with time zone */
        end_time?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<TradeGroupFills[], any>({
        path: `/trade_group_fills`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  tradeGroups = {
    /**
     * No description
     *
     * @tags trade_groups
     * @name TradeGroupsList
     * @request GET:/trade_groups
     * @response `200` `(TradeGroups)[]` OK
     * @response `206` `unknown` Partial Content
     */
    tradeGroupsList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format bigint */
        asset_id?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format boolean */
        open?: string
        /** @format bigint */
        open_predictions?: string
        /** @format app_data.quantity */
        take_profit_price?: string
        /** @format app_data.quantity */
        stop_loss_price?: string
        /** @format numeric */
        expected_move?: string
        /** @format numeric */
        profit?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<TradeGroups[], any>({
        path: `/trade_groups`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  tradeRequestStatus = {
    /**
     * No description
     *
     * @tags trade_request_status
     * @name TradeRequestStatusList
     * @request GET:/trade_request_status
     * @response `200` `(TradeRequestStatus)[]` OK
     * @response `206` `unknown` Partial Content
     */
    tradeRequestStatusList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        status?: string
        /** @format text[] */
        errors?: string
        /** @format bigint */
        orders?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<TradeRequestStatus[], any>({
        path: `/trade_request_status`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  tradeRequests = {
    /**
     * No description
     *
     * @tags trade_requests
     * @name TradeRequestsDelete
     * @request DELETE:/trade_requests
     * @response `204` `unknown` No Content
     */
    tradeRequestsDelete: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        id?: string
        /** @format bigint */
        market_id?: string
        /** @format numeric */
        price?: string
        /** @format numeric */
        quantity?: string
        /** @format app_data.direction */
        direction?: string
        /** @format app_data.time_in_force */
        time_in_force?: string
        /** @format boolean */
        post_only?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
        /** @format bigint */
        strategy_id?: string
        /** @format app_data.margin_mode */
        margin_mode?: string
        /** @format app_data.trade_source */
        source?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/trade_requests`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags trade_requests
     * @name TradeRequestsList
     * @request GET:/trade_requests
     * @response `200` `(TradeRequests)[]` OK
     * @response `206` `unknown` Partial Content
     */
    tradeRequestsList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        id?: string
        /** @format bigint */
        market_id?: string
        /** @format numeric */
        price?: string
        /** @format numeric */
        quantity?: string
        /** @format app_data.direction */
        direction?: string
        /** @format app_data.time_in_force */
        time_in_force?: string
        /** @format boolean */
        post_only?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
        /** @format bigint */
        strategy_id?: string
        /** @format app_data.margin_mode */
        margin_mode?: string
        /** @format app_data.trade_source */
        source?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<TradeRequests[], any>({
        path: `/trade_requests`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags trade_requests
     * @name TradeRequestsPartialUpdate
     * @request PATCH:/trade_requests
     * @response `204` `unknown` No Content
     */
    tradeRequestsPartialUpdate: (
      trade_requests: TradeRequests,
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        id?: string
        /** @format bigint */
        market_id?: string
        /** @format numeric */
        price?: string
        /** @format numeric */
        quantity?: string
        /** @format app_data.direction */
        direction?: string
        /** @format app_data.time_in_force */
        time_in_force?: string
        /** @format boolean */
        post_only?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
        /** @format bigint */
        strategy_id?: string
        /** @format app_data.margin_mode */
        margin_mode?: string
        /** @format app_data.trade_source */
        source?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/trade_requests`,
        method: 'PATCH',
        query: query,
        body: trade_requests,
        ...params,
      }),

    /**
     * No description
     *
     * @tags trade_requests
     * @name TradeRequestsCreate
     * @request POST:/trade_requests
     * @response `201` `unknown` Created
     */
    tradeRequestsCreate: (
      trade_requests: TradeRequests,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/trade_requests`,
        method: 'POST',
        query: query,
        body: trade_requests,
        ...params,
      }),
  }
  transferRequests = {
    /**
     * No description
     *
     * @tags transfer_requests
     * @name TransferRequestsDelete
     * @request DELETE:/transfer_requests
     * @response `204` `unknown` No Content
     */
    transferRequestsDelete: (
      query?: {
        /** @format bigint */
        transfer_request_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        from_wallet_id?: string
        /** @format bigint */
        to_wallet_id?: string
        /** @format numeric */
        amount?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/transfer_requests`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags transfer_requests
     * @name TransferRequestsList
     * @request GET:/transfer_requests
     * @response `200` `(TransferRequests)[]` OK
     * @response `206` `unknown` Partial Content
     */
    transferRequestsList: (
      query?: {
        /** @format bigint */
        transfer_request_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        from_wallet_id?: string
        /** @format bigint */
        to_wallet_id?: string
        /** @format numeric */
        amount?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<TransferRequests[], any>({
        path: `/transfer_requests`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags transfer_requests
     * @name TransferRequestsPartialUpdate
     * @request PATCH:/transfer_requests
     * @response `204` `unknown` No Content
     */
    transferRequestsPartialUpdate: (
      transfer_requests: TransferRequests,
      query?: {
        /** @format bigint */
        transfer_request_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        from_wallet_id?: string
        /** @format bigint */
        to_wallet_id?: string
        /** @format numeric */
        amount?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format bigint */
        created_by_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/transfer_requests`,
        method: 'PATCH',
        query: query,
        body: transfer_requests,
        ...params,
      }),

    /**
     * No description
     *
     * @tags transfer_requests
     * @name TransferRequestsCreate
     * @request POST:/transfer_requests
     * @response `201` `unknown` Created
     */
    transferRequestsCreate: (
      transfer_requests: TransferRequests,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/transfer_requests`,
        method: 'POST',
        query: query,
        body: transfer_requests,
        ...params,
      }),
  }
  transfers = {
    /**
     * No description
     *
     * @tags transfers
     * @name TransfersList
     * @request GET:/transfers
     * @response `200` `(Transfers)[]` OK
     * @response `206` `unknown` Partial Content
     */
    transfersList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        id?: string
        /** @format bigint */
        asset_id?: string
        /** @format numeric */
        amount?: string
        /** @format timestamp with time zone */
        sent_at?: string
        /** @format timestamp with time zone */
        received_at?: string
        /** @format text */
        from_exchange?: string
        /** @format text */
        to_exchange?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Transfers[], any>({
        path: `/transfers`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  twapLegs = {
    /**
     * No description
     *
     * @tags twap_legs
     * @name TwapLegsDelete
     * @request DELETE:/twap_legs
     * @response `204` `unknown` No Content
     */
    twapLegsDelete: (
      query?: {
        /** @format bigint */
        twap_leg_id?: string
        /** @format bigint */
        twap_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        creator_id?: string
        /** @format bigint */
        market_id?: string
        /** @format app_data.margin_mode */
        margin_mode?: string
        /** @format numeric */
        quantity?: string
        /** @format app_data.direction */
        direction?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format algo.execution_style */
        execution_style?: string
        /** @format bigint */
        hedge_market_id?: string
        /** @format app_data.margin_mode */
        hedge_margin_mode?: string
        /** @format interval */
        gtt_duration?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/twap_legs`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags twap_legs
     * @name TwapLegsList
     * @request GET:/twap_legs
     * @response `200` `(TwapLegs)[]` OK
     * @response `206` `unknown` Partial Content
     */
    twapLegsList: (
      query?: {
        /** @format bigint */
        twap_leg_id?: string
        /** @format bigint */
        twap_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        creator_id?: string
        /** @format bigint */
        market_id?: string
        /** @format app_data.margin_mode */
        margin_mode?: string
        /** @format numeric */
        quantity?: string
        /** @format app_data.direction */
        direction?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format algo.execution_style */
        execution_style?: string
        /** @format bigint */
        hedge_market_id?: string
        /** @format app_data.margin_mode */
        hedge_margin_mode?: string
        /** @format interval */
        gtt_duration?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<TwapLegs[], any>({
        path: `/twap_legs`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags twap_legs
     * @name TwapLegsPartialUpdate
     * @request PATCH:/twap_legs
     * @response `204` `unknown` No Content
     */
    twapLegsPartialUpdate: (
      twap_legs: TwapLegs,
      query?: {
        /** @format bigint */
        twap_leg_id?: string
        /** @format bigint */
        twap_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        creator_id?: string
        /** @format bigint */
        market_id?: string
        /** @format app_data.margin_mode */
        margin_mode?: string
        /** @format numeric */
        quantity?: string
        /** @format app_data.direction */
        direction?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format algo.execution_style */
        execution_style?: string
        /** @format bigint */
        hedge_market_id?: string
        /** @format app_data.margin_mode */
        hedge_margin_mode?: string
        /** @format interval */
        gtt_duration?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/twap_legs`,
        method: 'PATCH',
        query: query,
        body: twap_legs,
        ...params,
      }),

    /**
     * No description
     *
     * @tags twap_legs
     * @name TwapLegsCreate
     * @request POST:/twap_legs
     * @response `201` `unknown` Created
     */
    twapLegsCreate: (
      twap_legs: TwapLegs,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/twap_legs`,
        method: 'POST',
        query: query,
        body: twap_legs,
        ...params,
      }),
  }
  twapTradeRequestErrors = {
    /**
     * No description
     *
     * @tags twap_trade_request_errors
     * @name TwapTradeRequestErrorsList
     * @request GET:/twap_trade_request_errors
     * @response `200` `(TwapTradeRequestErrors)[]` OK
     * @response `206` `unknown` Partial Content
     */
    twapTradeRequestErrorsList: (
      query?: {
        /** @format bigint */
        twap_leg_id?: string
        /** @format text */
        error?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<TwapTradeRequestErrors[], any>({
        path: `/twap_trade_request_errors`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  twapTradeRequests = {
    /**
     * No description
     *
     * @tags twap_trade_requests
     * @name TwapTradeRequestsDelete
     * @request DELETE:/twap_trade_requests
     * @response `204` `unknown` No Content
     */
    twapTradeRequestsDelete: (
      query?: {
        /** @format bigint */
        twap_leg_id?: string
        /** @format bigint */
        trade_request_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/twap_trade_requests`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags twap_trade_requests
     * @name TwapTradeRequestsList
     * @request GET:/twap_trade_requests
     * @response `200` `(TwapTradeRequests)[]` OK
     * @response `206` `unknown` Partial Content
     */
    twapTradeRequestsList: (
      query?: {
        /** @format bigint */
        twap_leg_id?: string
        /** @format bigint */
        trade_request_id?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<TwapTradeRequests[], any>({
        path: `/twap_trade_requests`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags twap_trade_requests
     * @name TwapTradeRequestsPartialUpdate
     * @request PATCH:/twap_trade_requests
     * @response `204` `unknown` No Content
     */
    twapTradeRequestsPartialUpdate: (
      twap_trade_requests: TwapTradeRequests,
      query?: {
        /** @format bigint */
        twap_leg_id?: string
        /** @format bigint */
        trade_request_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/twap_trade_requests`,
        method: 'PATCH',
        query: query,
        body: twap_trade_requests,
        ...params,
      }),

    /**
     * No description
     *
     * @tags twap_trade_requests
     * @name TwapTradeRequestsCreate
     * @request POST:/twap_trade_requests
     * @response `201` `unknown` Created
     */
    twapTradeRequestsCreate: (
      twap_trade_requests: TwapTradeRequests,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/twap_trade_requests`,
        method: 'POST',
        query: query,
        body: twap_trade_requests,
        ...params,
      }),
  }
  twaps = {
    /**
     * No description
     *
     * @tags twaps
     * @name TwapsDelete
     * @request DELETE:/twaps
     * @response `204` `unknown` No Content
     */
    twapsDelete: (
      query?: {
        /** @format bigint */
        twap_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        creator_id?: string
        /** @format interval */
        duration?: string
        /** @format interval */
        frequency?: string
        /** @format integer */
        max_slippage?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        started_at?: string
        /** @format timestamp with time zone */
        ended_at?: string
        /** @format algo.twap_status */
        status?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/twaps`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags twaps
     * @name TwapsList
     * @request GET:/twaps
     * @response `200` `(Twaps)[]` OK
     * @response `206` `unknown` Partial Content
     */
    twapsList: (
      query?: {
        /** @format bigint */
        twap_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        creator_id?: string
        /** @format interval */
        duration?: string
        /** @format interval */
        frequency?: string
        /** @format integer */
        max_slippage?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        started_at?: string
        /** @format timestamp with time zone */
        ended_at?: string
        /** @format algo.twap_status */
        status?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Twaps[], any>({
        path: `/twaps`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags twaps
     * @name TwapsPartialUpdate
     * @request PATCH:/twaps
     * @response `204` `unknown` No Content
     */
    twapsPartialUpdate: (
      twaps: Twaps,
      query?: {
        /** @format bigint */
        twap_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        creator_id?: string
        /** @format interval */
        duration?: string
        /** @format interval */
        frequency?: string
        /** @format integer */
        max_slippage?: string
        /** @format timestamp with time zone */
        created_at?: string
        /** @format timestamp with time zone */
        started_at?: string
        /** @format timestamp with time zone */
        ended_at?: string
        /** @format algo.twap_status */
        status?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/twaps`,
        method: 'PATCH',
        query: query,
        body: twaps,
        ...params,
      }),

    /**
     * No description
     *
     * @tags twaps
     * @name TwapsCreate
     * @request POST:/twaps
     * @response `201` `unknown` Created
     */
    twapsCreate: (
      twaps: Twaps,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/twaps`,
        method: 'POST',
        query: query,
        body: twaps,
        ...params,
      }),
  }
  uncollateralizedPositions = {
    /**
     * No description
     *
     * @tags uncollateralized_positions
     * @name UncollateralizedPositionsList
     * @request GET:/uncollateralized_positions
     * @response `200` `(UncollateralizedPositions)[]` OK
     * @response `206` `unknown` Partial Content
     */
    uncollateralizedPositionsList: (
      query?: {
        /** @format bigint */
        asset_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        exchange_id?: string
        /** @format numeric */
        position?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<UncollateralizedPositions[], any>({
        path: `/uncollateralized_positions`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  upnl = {
    /**
     * No description
     *
     * @tags upnl
     * @name UpnlList
     * @request GET:/upnl
     * @response `200` `(Upnl)[]` OK
     * @response `206` `unknown` Partial Content
     */
    upnlList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        wallet_id?: string
        /** @format text */
        exchange?: string
        /** @format bigint */
        asset_id?: string
        /** @format public.citext */
        wallet_name?: string
        /** @format app_data.wallet_type */
        wallet_type?: string
        /** @format numeric */
        balance?: string
        /** @format numeric */
        upnl?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Upnl[], any>({
        path: `/upnl`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  userTenants = {
    /**
     * No description
     *
     * @tags user_tenants
     * @name UserTenantsDelete
     * @request DELETE:/user_tenants
     * @response `204` `unknown` No Content
     */
    userTenantsDelete: (
      query?: {
        /** @format bigint */
        user_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format app_data.tenant_role */
        role?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/user_tenants`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user_tenants
     * @name UserTenantsList
     * @request GET:/user_tenants
     * @response `200` `(UserTenants)[]` OK
     * @response `206` `unknown` Partial Content
     */
    userTenantsList: (
      query?: {
        /** @format bigint */
        user_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format app_data.tenant_role */
        role?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<UserTenants[], any>({
        path: `/user_tenants`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user_tenants
     * @name UserTenantsPartialUpdate
     * @request PATCH:/user_tenants
     * @response `204` `unknown` No Content
     */
    userTenantsPartialUpdate: (
      user_tenants: UserTenants,
      query?: {
        /** @format bigint */
        user_id?: string
        /** @format bigint */
        tenant_id?: string
        /** @format app_data.tenant_role */
        role?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/user_tenants`,
        method: 'PATCH',
        query: query,
        body: user_tenants,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user_tenants
     * @name UserTenantsCreate
     * @request POST:/user_tenants
     * @response `201` `unknown` Created
     */
    userTenantsCreate: (
      user_tenants: UserTenants,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/user_tenants`,
        method: 'POST',
        query: query,
        body: user_tenants,
        ...params,
      }),
  }
  users = {
    /**
     * No description
     *
     * @tags users
     * @name UsersDelete
     * @request DELETE:/users
     * @response `204` `unknown` No Content
     */
    usersDelete: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/users`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersList
     * @request GET:/users
     * @response `200` `(Users)[]` OK
     * @response `206` `unknown` Partial Content
     */
    usersList: (
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<Users[], any>({
        path: `/users`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersPartialUpdate
     * @request PATCH:/users
     * @response `204` `unknown` No Content
     */
    usersPartialUpdate: (
      users: Users,
      query?: {
        /** @format bigint */
        id?: string
        /** @format text */
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/users`,
        method: 'PATCH',
        query: query,
        body: users,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersCreate
     * @request POST:/users
     * @response `201` `unknown` Created
     */
    usersCreate: (
      users: Users,
      query?: {
        /** Filtering Columns */
        select?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<unknown, any>({
        path: `/users`,
        method: 'POST',
        query: query,
        body: users,
        ...params,
      }),
  }
  walletBalances = {
    /**
     * No description
     *
     * @tags wallet_balances
     * @name WalletBalancesList
     * @request GET:/wallet_balances
     * @response `200` `(WalletBalances)[]` OK
     * @response `206` `unknown` Partial Content
     */
    walletBalancesList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format bigint */
        wallet_id?: string
        /** @format numeric */
        balance?: string
        /** @format numeric */
        free?: string
        /** @format timestamp with time zone */
        updated_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<WalletBalances[], any>({
        path: `/wallet_balances`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  walletUpnl = {
    /**
     * No description
     *
     * @tags wallet_upnl
     * @name WalletUpnlList
     * @request GET:/wallet_upnl
     * @response `200` `(WalletUpnl)[]` OK
     * @response `206` `unknown` Partial Content
     */
    walletUpnlList: (
      query?: {
        /** @format bigint */
        tenant_id?: string
        /** @format text */
        exchange?: string
        /** @format bigint */
        wallet_id?: string
        /** @format bigint */
        asset_id?: string
        /** @format public.citext */
        wallet_name?: string
        /** @format app_data.wallet_type */
        wallet_type?: string
        /** @format numeric */
        balance?: string
        /** @format numeric */
        derived_upnl?: string
        /** @format timestamp with time zone */
        derived_upnl_updated_at?: string
        /** @format numeric */
        exchange_upnl?: string
        /** @format timestamp with time zone */
        exchange_upnl_updated_at?: string
        /** Filtering Columns */
        select?: string
        /** Ordering */
        order?: string
        /** Limiting and Pagination */
        offset?: string
        /** Limiting and Pagination */
        limit?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<WalletUpnl[], any>({
        path: `/wallet_upnl`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
}
